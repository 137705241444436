import {InternalError} from '@/errors/InternalError';
import {UserStatusError} from '@/errors/UserStatusError';
import {ValidationError} from '@/errors/ValidationError';
import {UserStatus} from '@/graphql/__generated__/graphql';
import {ServiceInternalErrors} from '@/types/serviceErrors';
import {signInWithCustomToken, updateEmail} from 'firebase/auth';
import * as yup from 'yup';

import {sendSignInCodeEmail, validateSignInCode} from '@/graphql/mutations';
import {ApolloClient, ApolloError} from '@apollo/client';

import {fbAuth} from '@/lib/firebase';

import TrackingService from '../tracking/TrackingService';
import {BaseAuthenticationMethod} from './BaseAuthenticationMethod';
import {
  AuthenticationMethod,
  HandleAuthenticationErrorProps,
  IAuthenticationMethod,
  SendVerificationMechanismProps,
  ValidateIdentifierProps,
  ValidateVerificationMechanismProps,
} from './IAuthenticationMethod';
import { isNotError } from '@/utils/error';
import { ERROR_LEVEL } from '@/constants';
import { ERROR_MESSAGE_ID } from '@/types/tracking';

export class EmailAuthenticationService extends BaseAuthenticationMethod implements IAuthenticationMethod {
  public COOLDOWN_TIME = 2;
  public METHOD = AuthenticationMethod.EMAIL;
  public VERIFICATION_MECHANISM_ICON = 'mail';
  public VERIFICATION_MECHANISM_ADVICE =
    "If you didn't receive an email, check your spam or contact our support.";

  constructor(tracking: TrackingService, client: ApolloClient<any>) {
    super(tracking, client, 'email_authentication');
  }

  static THROW_ON = super.THROW_ON;

  static INTERNAL_ERROR: ServiceInternalErrors = {
    ...super.INTERNAL_ERROR,
    EMAIL_NOT_PROVIDED_SEND: {
      CODE: 'auth/kasta/cannot-send-email-code-because-email-not-provided',
      MSG: 'Cannot send email code because email was not provided. Please contact customer support.',
    },
    EMAIL_NOT_PROVIDED_FOR_VALIDATION: {
      CODE: 'auth/kasta/email-not-provided-for-validation',
      MSG: 'Cannot validate email because it was not provided. Please contact customer support.',
    },
    EMAIL_NOT_PROVIDED_IN_CODE_VALIDATION: {
      CODE: 'auth/kasta/cannot-validate-email-code-because-email-not-provided',
      MSG: 'Cannot verify email code because email was not provided. Please contact customer support.',
    },
    CODE_NOT_PROVIDED_IN_CODE_VALIDATION: {
      CODE: 'auth/kasta/cannot-validate-email-code-because-code-not-provided',
      MSG: 'Cannot verify email code because code was not provided. Please contact customer support.',
    },
    CUSTOM_TOKEN_NOT_RETURNED: {
      CODE: 'auth/kasta/custom-token-not-returned',
      MSG: 'Cannot sign in because we received no custom token. Please contact customer support.',
    },
  } as const;

  // Used in components to show users fallback error messages in case of an unknown/unregistered error.
  static GENERIC_ERROR = {
    VALIDATE_EMAIL: 'Could not validate email. Please try again.',
    VALIDATE_CODE: 'Could not validate code. Please try again.',
    SEND_EMAIL: 'Could not send email to this address. Please try again.',
    INITIATE_SIGNUP: 'Could not initiate signup. Please try again.',
  } as const;

  static EMAIL_SCHEMA = yup
    .object({
      email: yup.string().strict().trim().lowercase().email('Invalid email.').required('Email required.'),
    })
    .required();

  public saveLastAuthenticationMethod(): void {
    super.saveLastAuthenticationMethod(AuthenticationMethod.EMAIL);
  }

  async validateIdentifier({identifier: email, throwOn}: Required<ValidateIdentifierProps>) {
    try {
      if (!fbAuth) {
        throw new InternalError({
          code: EmailAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.CODE,
          message: EmailAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.MSG,
        });
      }

      if (!email) {
        throw new InternalError({
          code: EmailAuthenticationService.INTERNAL_ERROR.EMAIL_NOT_PROVIDED_FOR_VALIDATION.CODE,
          message: EmailAuthenticationService.INTERNAL_ERROR.EMAIL_NOT_PROVIDED_FOR_VALIDATION.MSG,
        });
      }

      const userExistsInFB = await this.doesUserExistsInFirebase(email);
      const {userStatus, userExistsInDB} = await this.doesEmailExistsInDatabase(email);

      // Useful in the sign in flow
      if (!userExistsInDB && throwOn === EmailAuthenticationService.THROW_ON.USER_NOT_FOUND) {
        if ([UserStatus.Created, UserStatus.PasswordSet].includes(userStatus as UserStatus)) {
          throw new UserStatusError({
            message: EmailAuthenticationService.VALIDATION_ERROR.RESUME_SIGN_UP.MSG,
            code: EmailAuthenticationService.VALIDATION_ERROR.RESUME_SIGN_UP.CODE,
            status: userStatus as UserStatus,
          });
        }

        throw new ValidationError({
          code: EmailAuthenticationService.VALIDATION_ERROR.USER_NOT_FOUND_IN_DB.CODE,
          message: EmailAuthenticationService.VALIDATION_ERROR.USER_NOT_FOUND_IN_DB.MSG,
        });
      }

      // Useful in the sign up flow
      if (userExistsInDB && throwOn === EmailAuthenticationService.THROW_ON.USER_ALREADY_EXISTS) {
        if ([UserStatus.Created, UserStatus.PasswordSet].includes(userStatus as UserStatus)) {
          throw new UserStatusError({
            message: EmailAuthenticationService.VALIDATION_ERROR.RESUME_SIGN_UP.MSG,
            code: EmailAuthenticationService.VALIDATION_ERROR.RESUME_SIGN_UP.CODE,
            status: userStatus as UserStatus,
          });
        }

        throw new ValidationError({
          code: EmailAuthenticationService.VALIDATION_ERROR.USER_ALREADY_EXISTS_IN_DB.CODE,
          message: EmailAuthenticationService.VALIDATION_ERROR.USER_ALREADY_EXISTS_IN_DB.MSG,
        });
      }

      if (userExistsInDB && !userExistsInFB) {
        throw new ValidationError({
          code: EmailAuthenticationService.VALIDATION_ERROR.USER_NOT_LINKED_IN_FB.CODE,
          message: EmailAuthenticationService.VALIDATION_ERROR.USER_NOT_LINKED_IN_FB.MSG,
        });
      }
    } catch (error) {

      this.handleIsNotError(error);
   
      this.tracking.logServiceError({error, apolloErrors: EmailAuthenticationService.APOLLO_ERROR});
      throw error;
    }
  }

  async sendVerificationMechanism({
    identifier: email,
  }: Required<Omit<SendVerificationMechanismProps, 'operation'>>) {
    try {
      if (!fbAuth) {
        throw new InternalError({
          code: EmailAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.CODE,
          message: EmailAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.MSG,
        });
      }

      if (!email) {
        throw new InternalError({
          code: EmailAuthenticationService.INTERNAL_ERROR.EMAIL_NOT_PROVIDED_SEND.CODE,
          message: EmailAuthenticationService.INTERNAL_ERROR.EMAIL_NOT_PROVIDED_SEND.MSG,
        });
      }

      fbAuth.useDeviceLanguage();

      await this.client.mutate({
        mutation: sendSignInCodeEmail,
        variables: {
          input: {email},
        },
      });

      this.saveIdentifier(email);
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: EmailAuthenticationService.APOLLO_ERROR});
      throw error;
    }
  }

  async validateVerificationMechanism({
    identifier: email,
    code,
  }: Required<Omit<ValidateVerificationMechanismProps, 'operation'>>) {
    try {
      if (!fbAuth) {
        throw new InternalError({
          code: EmailAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.CODE,
          message: EmailAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.MSG,
        });
      }

      if (!email) {
        throw new InternalError({
          code: EmailAuthenticationService.INTERNAL_ERROR.EMAIL_NOT_PROVIDED_IN_CODE_VALIDATION.CODE,
          message: EmailAuthenticationService.INTERNAL_ERROR.EMAIL_NOT_PROVIDED_IN_CODE_VALIDATION.MSG,
        });
      }

      if (!code) {
        throw new InternalError({
          code: EmailAuthenticationService.INTERNAL_ERROR.CODE_NOT_PROVIDED_IN_CODE_VALIDATION.CODE,
          message: EmailAuthenticationService.INTERNAL_ERROR.CODE_NOT_PROVIDED_IN_CODE_VALIDATION.MSG,
        });
      }

      const response = await this.client.mutate({
        mutation: validateSignInCode,
        variables: {
          input: {code, email},
        },
      });

      const {customToken} = response.data?.validateSignInCode || {};

      if (!customToken) {
        throw new InternalError({
          code: EmailAuthenticationService.INTERNAL_ERROR.CUSTOM_TOKEN_NOT_RETURNED.CODE,
          message: EmailAuthenticationService.INTERNAL_ERROR.CUSTOM_TOKEN_NOT_RETURNED.MSG,
        });
      }

      await signInWithCustomToken(fbAuth, customToken);

      if (fbAuth?.currentUser) {
        await updateEmail(fbAuth.currentUser, email);
      }
    } catch (error: any) {
      this.tracking.logServiceError({error, apolloErrors: EmailAuthenticationService.APOLLO_ERROR});
      throw error;
    }
  }

  handleAuthenticationError({error, onUserNotFound, ...props}: HandleAuthenticationErrorProps): void {
    if (error instanceof ApolloError) {
      // This error should be treated like a user-not-found validation error
      if (EmailAuthenticationService.APOLLO_ERROR.USER_NOT_FOUND_BY_EMAIL.MATCH.test(error.message)) {
        onUserNotFound?.();
      }
    }

    super.handleAuthenticationError({...props, error, onUserNotFound});
  }
}
