import viewports from '@kasta-io/tokens/primitives/viewport.json';
import styled, {css} from 'styled-components';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';
import type {TypographyVariants} from 'src/lib/types';

export interface TypographyProps {
  $variant: TypographyVariants;
  $alternativeVariant?: TypographyVariants | '';
  $breakpoint?: string;
  $weight?: string | undefined;
  $color?: ColorType;
  $transform?: 'none' | 'lowercase' | 'uppercase' | 'capitalize';
}

const StyledTypography = styled.p<TypographyProps>`
  font-family: ${({$variant}: TypographyProps) => $variant && `var(--font-styles-${$variant}-family)`};
  font-size: ${({$variant}: TypographyProps) => $variant && `var(--font-styles-${$variant}-size)`};
  font-style: normal;
  font-weight: ${({$variant, $weight}: TypographyProps) =>
    $weight ? $weight : `var(--font-styles-${$variant}-weight)`};
  line-height: ${({$variant}: TypographyProps) => $variant && `var(--font-styles-${$variant}-lineHeight)`};
  color: ${({$color}: TypographyProps) => ($color ? $color : 'var(--colors-text-onBackground)')};
  text-transform: ${({$transform}: TypographyProps) => ($transform ? $transform : 'none')};
  letter-spacing: ${({$variant}: TypographyProps) => $variant && `var(--font-styles-${$variant}-spacing)`};

  ${({$breakpoint, $alternativeVariant, $weight}: TypographyProps) =>
    $alternativeVariant !== '' &&
    css`
      @media screen and (max-width: ${viewports[$breakpoint as keyof typeof viewports]}) {
        font-family: var(--font-styles-${$alternativeVariant}-family);
        font-size: var(--font-styles-${$alternativeVariant}-size);
        font-weight: ${$weight ? $weight : `var(--font-styles-${$alternativeVariant}-weight)`};
        line-height: var(--font-styles-${$alternativeVariant}-lineHeight);
        letter-spacing: var(--font-styles-${$alternativeVariant}-spacing);
      }
    `}
`;

export {StyledTypography};
