import {Button, PopUp, Typography} from '@kasta-io/components';

interface Props {
  type: 'cash' | 'crypto';
  isVisible: boolean;
  togglePopUp: () => void;
}

export const KycVerificationInProgressDialog = ({type, isVisible, togglePopUp}: Props) => {
  return (
    <PopUp
      open={isVisible}
      popOver
      onClose={togglePopUp}
      closeOnBackdropClick
      hasCloseButton={false}
      title="Verification in progress"
      icon={{
        type: 'custom',
        name: 'yellow-random-column',
      }}
      footer={<Button onClick={togglePopUp}>Close</Button>}>
      <Typography variant="body2" color="var(--colors-text-onBackgroundMedium)">
        {type === 'crypto'
          ? "Verifying your info. We'll email you once it's done."
          : "Verifying your info. We'll email you once it's done, so you can proceed with cash activation."}
      </Typography>
    </PopUp>
  );
};
