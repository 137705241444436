import {ERROR_LEVEL} from '@/constants';
import {zendeskService} from '@/services/support/ZendeskService';
import {tracking} from '@/services/tracking/TrackingService';
import {isDev} from '@/utils/device';

export const handleContactSupport = () => {
  try {
    if (isDev) {
      alert("You're in development mode");
    } else {
      zendeskService.openMessenger();
      tracking.logEvent(tracking.events.open_chat_support());
    }
  } catch (error: any) {
    tracking.logError({
      error_message: error.message || JSON.stringify(error),
      error_level: ERROR_LEVEL.CRITICAL,
      error_message_id: 'zendesk/kasta/unable-to-open-messenger',
    });
  }
};
