import {cssVar, lighten} from 'polished';
import {DefaultTransition} from 'src/styled/transitions';
import styled, {css} from 'styled-components';

interface CheckboxProps {
  disabled?: boolean | undefined;
}

const StyledCheckbox = styled.input<CheckboxProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  appearance: none;
  cursor: pointer;
  border: 2px solid
    ${props =>
      props.disabled
        ? lighten(0.2, `${cssVar('--colors-text-onBackgroundLight')}`)
        : 'var(--colors-primary-500)'};
  border-radius: 4px;

  &,
  &:before {
    ${DefaultTransition()};
  }

  &:before {
    display: block;
    width: 14px;
    height: 14px;
    background-color: ${props =>
      props.disabled
        ? lighten(0.2, `${cssVar('--colors-text-onBackgroundLight')}`)
        : 'var(--colors-primary-500)'};
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
    transform: scale(0);
  }

  &:checked {
    &:before {
      transform: scale(1);
    }
  }

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

export {StyledCheckbox};
