import {css} from 'styled-components';

import type {TypographyVariants} from 'src/lib/types';

export const pxToRem = (valueInPx: number): number => {
  return valueInPx / 16;
};

export const ptToRem = (valueInPt: number): number => {
  return pxToRem(valueInPt * 4);
};

export const remToPx = (valueInRem: number): number => {
  return valueInRem * 16;
};

export const ptToPx = (valueInPt: number): number => {
  return valueInPt * 4;
};

export const defaultTypographyStyles = (variant: TypographyVariants) => {
  return css`
    font-family: var(--font-styles-${variant}-family);
    font-size: var(--font-styles-${variant}-size);
    font-style: normal;
    font-weight: var(--font-styles-${variant}-weight);
    line-height: var(--font-styles-${variant}-lineHeight);
    letter-spacing: var(--font-styles-${variant}-spacing);
  `;
};
