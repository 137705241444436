import colors from '@kasta-io/tokens/primitives/colors.json';
import viewports from '@kasta-io/tokens/primitives/viewport.json';
import {rgba} from 'polished';
import {DefaultBlockPadding, DefaultBlockRadius} from 'src/styled/mixins';
import {TransitionClasses} from 'src/styled/transitions';
import styled, {css} from 'styled-components';

interface PopUpProps {
  $popOver?: boolean;
  $pageCentered?: boolean;
  $fixed?: boolean;
  $overflow?: string;
  $fullScreen?: string;
}

interface ContentProps {
  $hasImage?: boolean;
  $hasIcon?: boolean;
  $hasButtons?: boolean;
  $hasBackButton?: boolean;
  $hasCloseButton?: boolean;
  $hasActions?: boolean;
  $alignment?: string;
}

const StyledHeader = styled.div<ContentProps>`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;

  button[aria-label='Close popup'] {
    margin-left: auto;
  }
`;

const StyledHeaderImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledTitleContainer = styled.div<ContentProps>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${({$alignment, $hasButtons, $hasBackButton, $hasCloseButton, $hasIcon, $hasImage}: ContentProps) =>
      !$hasIcon && !$hasImage
        ? $alignment === 'center' && $hasButtons
          ? css`
              padding: 0 48px;
            `
          : $hasBackButton && $hasCloseButton
            ? css`
                padding: 0 48px;
              `
            : $hasBackButton
              ? css`
                  padding-left: 48px;
                `
              : $hasCloseButton
                ? css`
                    padding-right: 48px;
                  `
                : null
        : null}
  }
`;

const StyledContent = styled.div<ContentProps>`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  gap: 16px;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  text-align: ${({$alignment}: ContentProps) => $alignment};

  ${({$hasButtons, $hasIcon}: ContentProps) =>
    $hasButtons && !$hasIcon
      ? css`
          padding-top: 3px;
        `
      : null}

  ${({$hasImage}: ContentProps) =>
    $hasImage &&
    css`
      padding-top: 280px;
    `}

  & > [role='icon'] {
    display: inline-block;
    margin: 0 auto;
  }

  @media screen and (min-width: ${viewports['tablet']}) {
    gap: 24px;

    ${({$hasImage}: ContentProps) =>
      $hasImage &&
      css`
        padding-top: 280px;
      `}
  }
`;

const StyledFooter = styled.div`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  gap: 8px;
  align-items: stretch;
  justify-content: stretch;

  button {
    flex: 1;
    width: 100%;
  }

  @media screen and (min-width: ${viewports['tablet']}) {
    flex-direction: row;
  }
`;

const StyledBackdrop = styled.div<PopUpProps>`
  ${({$fixed}: PopUpProps) =>
    $fixed
      ? css`
          position: relative;
        `
      : css`
          position: fixed;
          z-index: var(--model-zindex-popup);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-color: ${rgba(colors.text.onBackground, 0.68)};
          ${TransitionClasses({selector: 'popup'})};
        `}

  ${({$pageCentered}: PopUpProps) =>
    !$pageCentered &&
    css`
      @media screen and (max-width: ${viewports['tablet']}) {
        align-items: flex-end;
      }
    `}
`;

const StyledPopUp = styled.div<PopUpProps>`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  max-width: 90%;
  max-height: 90%;
  ${DefaultBlockPadding};
  overflow-y: ${({$overflow}: PopUpProps) => $overflow};
  scrollbar-width: none;
  background-color: var(--colors-background-paper);
  ${DefaultBlockRadius};
  box-shadow: var(--shadow--24dp);

  @media screen and (min-width: ${viewports['tablet']}) {
    width: 554px;
  }

  @media screen and (max-width: ${viewports['tablet']}) {
    max-height: calc(100% - 48px);

    ${({$pageCentered}: PopUpProps) =>
      !$pageCentered &&
      css`
        margin-bottom: 24px;
      `}
  }

  ${({$fullScreen}: PopUpProps) =>
    $fullScreen !== '' &&
    css`
      @media screen and (max-width: ${viewports[$fullScreen as keyof typeof viewports]}) {
        width: 100vw;
        min-width: 100vw;
        height: 100vh;
        height: 100dvh;
        min-height: 100vh;
        min-height: 100dvh;
        max-height: 100vh;
        max-height: 100dvh;
        margin-bottom: 0;
        border-radius: 0;

        ${StyledContent} {
          flex: 1;
        }
      }
    `}

  ${({$popOver}: PopUpProps) =>
    $popOver &&
    css`
      @media screen and (max-width: ${viewports['tablet']}) {
        align-self: flex-end;
        width: 100%;
        max-width: 100%;
        padding-bottom: 16px;
        margin-bottom: 0;
        border-radius: 16px 16px 0 0;
        box-shadow: none;
      }
    `}
`;

export {
  StyledPopUp,
  StyledHeader,
  StyledHeaderImageContainer,
  StyledTitleContainer,
  StyledContent,
  StyledFooter,
  StyledBackdrop,
};
