import {useMemo, useRef, useState} from 'react';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {Skeleton} from 'primereact/skeleton';
import {classNames} from 'primereact/utils';

import {Currency} from '@/graphql/__generated__/graphql';
import {ValueOf} from '@/types/generics';
import {Button, Icon, List, SearchField, Typography} from '@kasta-io/components';

import {useAccount} from '@/contexts/AccountContext';
import {useAppConfig} from '@/contexts/AppConfigContext';
import {useComplianceContext} from '@/contexts/ComplianceContext';
import {useExchangeRates} from '@/contexts/ExchangeRatesContext';
import useIsTargetMedia from '@/hooks/useIsTargetMedia';
import {getFormattedAmount} from '@/utils/amounts';
import {mergeChangesAndCurrencies} from '@/utils/assets';

import type {ExtendedTransactionType, SWAP_DIRECTION_TYPE} from '@/types/transactions';

import s from './ExploreAssetList.module.scss';

const DynamicExploreAssetListItem = dynamic(
  () => import('@/components/explore/ExploreAssetListItem/ExploreAssetListItem'),
);

export const ExploreCategories = {
  MOST_POPULAR: 'most-popular',
  TOP_GAINERS: 'top-gainers',
  LOSERS: 'losers',
  STABLECOINS: 'stablecoins',
} as const;

export const PopularityWeight = {
  HIGHER: 0.5,
  AVERAGE: 0.3,
  LOWER: 0.2,
} as const;

interface ExploreAssetListProps {
  variant?: keyof typeof VARIANTS;
  isSearchEnabled?: boolean;
  isScrollEnabled?: boolean;
  tx?: ExtendedTransactionType | SWAP_DIRECTION_TYPE | null;
  title?: string;
  displayedAssetsCount?: number;
  showBottomDetails?: boolean;
  initiallySelectedCategory?: ValueOf<typeof ExploreCategories>;
}

const VARIANTS = {
  DEFAULT: 'DEFAULT',
  PORTFOLIO: 'PORTFOLIO',
  ASSET_SELECTOR: 'ASSET_SELECTOR',
  SWAP_FROM: 'SWAP_FROM',
  SWAP_TO: 'SWAP_TO',
} as const;

export default function ExploreAssetList({
  variant = VARIANTS.DEFAULT,
  tx = null,
  isSearchEnabled = true,
  isScrollEnabled = false,
  title,
  displayedAssetsCount,
  showBottomDetails = true,
  initiallySelectedCategory,
}: ExploreAssetListProps) {
  const {push} = useRouter();
  const {assetsChange24h, exchangeRatesError} = useExchangeRates();
  const {
    accountFormattedCryptoAssetList,
    accountFormattedCashAssetList,
    isAccountTotalFiatBalanceLoaded,
    queryError,
  } = useAccount();

  const hasError = queryError || (exchangeRatesError && exchangeRatesError !== 'Error message not found');

  const {canSkipKyc} = useComplianceContext();
  // If the user has NO KYC and the showBottomDetails is FALSE it means only the exchange rate of the asset should be shown
  const shouldShowExchangeRates = !canSkipKyc && !showBottomDetails;

  const [searchValue, setSearchValue] = useState<string>('');
  const isMobilePhone = useIsTargetMedia('tablet', 'less');
  const containerRef = useRef<HTMLDivElement>(null);

  const [selectedExploreCategory, setSelectedExploreCategory] = useState<ValueOf<typeof ExploreCategories>>(
    initiallySelectedCategory || ExploreCategories.MOST_POPULAR,
  );

  const {appConfig} = useAppConfig();
  const {
    cashEnabledPwa,
    cashDepositEnabledPwa,
    cashSwapEnabledPwa,
    cashTransferEnabledPwa,
    cashWithdrawEnabledPwa,
  } = appConfig;

  const isLoadingAssets = !isAccountTotalFiatBalanceLoaded;

  const showCashAssets = useMemo(() => {
    if (!cashEnabledPwa || !accountFormattedCashAssetList?.length) {
      return false;
    }

    const mapper: Record<string, boolean> = {
      DEPOSIT: cashDepositEnabledPwa,
      TRANSFER: cashTransferEnabledPwa,
      WITHDRAW: cashWithdrawEnabledPwa,
      SWAP: cashSwapEnabledPwa,
      SWAP_FROM: cashSwapEnabledPwa,
      SWAP_TO: cashSwapEnabledPwa,
    };

    return mapper[tx || ''] ?? cashEnabledPwa;
  }, [
    cashEnabledPwa,
    accountFormattedCashAssetList?.length,
    tx,
    cashDepositEnabledPwa,
    cashTransferEnabledPwa,
    cashWithdrawEnabledPwa,
    cashSwapEnabledPwa,
  ]);

  const filterSelectedExploreItem = (selected: string, assetArray: any[]) => {
    switch (selected) {
      case ExploreCategories.MOST_POPULAR:
        return assetArray.sort((a, b) => {
          // Force KASTA to be always the 1st crypto at the asset list
          if (b.currency === Currency.Kasta) return 1;
          if (a.currency === Currency.Kasta) return -1;
          return b.popularityIndice - a.popularityIndice;
        });
      case ExploreCategories.TOP_GAINERS:
        return assetArray.sort((a, b) => b.change24h - a.change24h);
      case ExploreCategories.LOSERS:
        return assetArray.sort((a, b) => a.change24h - b.change24h);
      case ExploreCategories.STABLECOINS:
        return assetArray.filter(
          ({currency}: {currency: string}) => currency === Currency.Usdt || currency === Currency.Usdc,
        );
      default:
        return [...assetArray];
    }
  };

  const filteredAssetList = useMemo(() => {
    if (isLoadingAssets) {
      return null;
    }

    const assetArray = [
      ...(showCashAssets ? accountFormattedCashAssetList : []),
      ...accountFormattedCryptoAssetList,
    ];

    const mergedChangesAndCurrencies = mergeChangesAndCurrencies(assetsChange24h, assetArray);

    const assetArrayFiltered = filterSelectedExploreItem(selectedExploreCategory, mergedChangesAndCurrencies)
      // based on displayedAssetsCount, show only the first N assets
      .slice(0, displayedAssetsCount)
      // Filter RAMP unsupportted assets to buy or sell, list only assets that are supported
      .filter(({buySymbol, sellSymbol}) => {
        // This will allow showing KASTA but still hide all the other currencies not available
        if (tx === 'PURCHASE') {
          return buySymbol !== undefined;
        }
        if (tx === 'SELL') {
          return sellSymbol !== undefined;
        }
        return true;
      })
      .filter(
        ({name, currency}) =>
          name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
          currency.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
      );

    // Override values to show price for 1 unit of a token when the user has no KYC
    if (shouldShowExchangeRates) {
      return assetArrayFiltered.map(item => ({
        ...item,
        convertedAmountFormatted: getFormattedAmount({
          currency: Currency.Usd,
          value: item.usdExchangeRate,
          decimalsLength: 4,
        }),
      }));
    }

    return assetArrayFiltered;
  }, [
    isLoadingAssets,
    showCashAssets,
    accountFormattedCashAssetList,
    accountFormattedCryptoAssetList,
    assetsChange24h,
    selectedExploreCategory,
    displayedAssetsCount,
    shouldShowExchangeRates,
    tx,
    searchValue,
  ]);

  const wrapperClassnames = classNames(s.wrapper, {
    [s.noFooterPadding]: isSearchEnabled,
  });

  const listClassnames = classNames(s.list, {
    [s.scroll]: isScrollEnabled,
  });

  const containerClassnames = classNames(s.container, {
    [s.scroll]: isScrollEnabled,
  });

  const handleMenuClick = (item: ValueOf<typeof ExploreCategories>) => {
    setSelectedExploreCategory(item);
  };

  return (
    <section className={wrapperClassnames}>
      {isSearchEnabled && (
        <div className={s.wrapperSearch}>
          <SearchField
            name="asset-search-input"
            onChange={searchValue => setSearchValue(searchValue.target.value)}
            type={'search'}
            className={s.search}
            placeholder="Search assets"
          />
        </div>
      )}

      <div className={containerClassnames} ref={containerRef}>
        <div className={listClassnames}>
          {title && (
            <Typography
              as="h6"
              variant={isMobilePhone ? 'subtitle2' : 'h7'}
              weight="bold"
              color="var(--colors-text-onBackgroundMedium)"
              className={s.title}>
              {title}
            </Typography>
          )}

          <ul className={s.exploreMenu}>
            <li
              className={selectedExploreCategory === ExploreCategories.MOST_POPULAR ? s.active : ''}
              onClick={() => handleMenuClick(ExploreCategories.MOST_POPULAR)}>
              Most popular
            </li>
            <li
              className={selectedExploreCategory === ExploreCategories.TOP_GAINERS ? s.active : ''}
              onClick={() => handleMenuClick(ExploreCategories.TOP_GAINERS)}>
              Top Gainers
            </li>
            <li
              className={selectedExploreCategory === ExploreCategories.LOSERS ? s.active : ''}
              onClick={() => handleMenuClick(ExploreCategories.LOSERS)}>
              Losers
            </li>
            <li
              className={selectedExploreCategory === ExploreCategories.STABLECOINS ? s.active : ''}
              onClick={() => handleMenuClick(ExploreCategories.STABLECOINS)}>
              Stablecoins
            </li>
          </ul>

          {isLoadingAssets || !filteredAssetList ? (
            <div className={s.wrapperSkeleton}>
              {[...Array(6)].map((_, i) => (
                <AssetListSkeleton key={i} />
              ))}
            </div>
          ) : (
            <>
              {hasError ? (
                <FailedToLoadAssets />
              ) : (
                <>
                  <List
                    className={s.wrapperAssetList}
                    items={filteredAssetList.map(asset => ({
                      id: asset.currency,
                      children: (
                        <DynamicExploreAssetListItem
                          asset={asset}
                          isExploreSection={true}
                          showBottomDetails={!shouldShowExchangeRates}
                        />
                      ),
                    }))}
                  />
                  {displayedAssetsCount && filteredAssetList.length !== 0 && (
                    <div className={s.footer}>
                      <Button
                        onClick={() => push(`/explore?selected=${selectedExploreCategory}`)}
                        size="xs"
                        as="a"
                        type="tertiary">
                        Explore More
                      </Button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

function FailedToLoadAssets() {
  return (
    <div className={s.failedToLoad}>
      <Icon name="fail-circle" color="var(--colors-danger-500)" size={24} />
      <Typography variant="h7" color="var(--colors-text-onBackgroundMedium)" className={s.title}>
        Failed to Load
      </Typography>
      <Typography variant="caption1" color="var(--colors-text-onBackgroundMedium)" className={s.description}>
        Looks like there&apos;s a problem loading your Explore page. Please try again.
      </Typography>
    </div>
  );
}

function AssetListSkeleton() {
  const skeletonClassnames = classNames(s.skeleton, s.flat);

  return (
    <li className={skeletonClassnames}>
      <Skeleton size="4rem" />
      <div className={s.body}>
        <div>
          <Skeleton width="7rem" height="1.6rem" />
          <Skeleton width="3rem" height="1.4rem" />
        </div>
        <div>
          <Skeleton width="12rem" height="1.6rem" />
          <Skeleton width="4rem" height="1.4rem" />
        </div>
      </div>
    </li>
  );
}
