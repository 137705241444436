import {HTMLAttributes} from 'react';

import {Icon} from 'src/atoms/Icon/Icon';

import type {IconType} from 'src/lib/types';

import {StyledFloatingActionButton} from './styled';

export interface FloatingActionButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label: string;
  icon: IconType;
  badge?: boolean;
  disabled?: boolean;
  as?: keyof JSX.IntrinsicElements;
}

const FloatingActionButton = ({
  label = '',
  icon = {
    type: 'kastaicon',
    name: '',
    color: 'var(--colors-text-onPrimary)',
  },
  badge = false,
  disabled = false,
  ...props
}: FloatingActionButtonProps) => (
  <StyledFloatingActionButton $badge={badge} disabled={disabled} {...props} aria-label={label}>
    <Icon {...icon} size={24} />

    {label}
  </StyledFloatingActionButton>
);

export {FloatingActionButton};
