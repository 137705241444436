import {InternalError} from '@/errors/InternalError';
import {ValidationError} from '@/errors/ValidationError';
import {ServiceInternalErrors} from '@/types/serviceErrors';
import {
  ConfirmationResult,
  linkWithPhoneNumber,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import * as yup from 'yup';

import {ApolloClient} from '@apollo/client';

import {ERROR_LEVEL, ONLY_NUMERIC_REGEXP} from '@/constants';
import {fbAuth} from '@/lib/firebase';

import TrackingService from '../tracking/TrackingService';
import {BaseAuthenticationMethod} from './BaseAuthenticationMethod';
import {
  AuthenticationMethod,
  IAuthenticationMethod,
  SendVerificationMechanismProps,
  ValidateIdentifierProps,
  ValidateVerificationMechanismProps,
} from './IAuthenticationMethod';
import { isNotError } from '@/utils/error';
import { ERROR_MESSAGE_ID } from '@/types/tracking';

declare global {
  interface Window {
    confirmationResult: ConfirmationResult;
  }
}

export class PhoneNumberAuthenticationService
  extends BaseAuthenticationMethod
  implements IAuthenticationMethod
{
  public METHOD = AuthenticationMethod.PHONE_NUMBER;
  public COOLDOWN_TIME = 1;
  public VERIFICATION_MECHANISM_ICON = 'phone';
  public VERIFICATION_MECHANISM_ADVICE = 'Check your phone to enter the code from SMS.';

  constructor(tracking: TrackingService, client: ApolloClient<any>) {
    super(tracking, client, 'phone_number_authentication');
  }

  static INTERNAL_ERROR: ServiceInternalErrors = {
    ...super.INTERNAL_ERROR,
    PHONE_NUMBER_NOT_PROVIDED_FOR_VALIDATION: {
      CODE: 'auth/kasta/phone-number-not-provided-for-validation',
      MSG: 'Cannot validate phone number because it was not provided. Please contact customer support.',
    },
    PHONE_NUMBER_NOT_PROVIDED_SEND: {
      CODE: 'auth/kasta/cannot-send-sms-code-because-phone-number-not-provided',
      MSG: 'Cannot send SMS code because phone number was not provided. Please contact customer support.',
    },
    CODE_NOT_PROVIDED_VALIDATE: {
      CODE: 'auth/kasta/cannot-validate-sms-code-because-code-not-provided',
      MSG: 'Cannot verify SMS code because code was not provided. Please contact customer support.',
    },
  } as const;

  // Used in components to show users fallback error messages in case of an unknown/unregistered error.
  static GENERIC_ERROR = {
    SEND_SMS: 'Could not send SMS code to this phone number. Please try again.',
    VALIDATE_CODE: 'Could not validate code. Please try again.',
  } as const;

  static PHONE_NUMBER_SCHEMA = yup
    .object({
      countryCode: yup.string().required(),
      phoneNumber: yup
        .string()
        .strict()
        .trim('Phone number must not contain empty spaces.')
        .min(5, 'Phone number must be at least 5 digits long.')
        .matches(ONLY_NUMERIC_REGEXP, 'Phone number must contain only numbers.')
        .required('Please enter a phone number.'),
    })
    .required();

  public saveLastAuthenticationMethod(): void {
    super.saveLastAuthenticationMethod(AuthenticationMethod.PHONE_NUMBER);
  }

  async validateIdentifier({identifier: phoneNumber, throwOn}: Required<ValidateIdentifierProps>) {
    try {
      if (!phoneNumber) {
        throw new InternalError({
          code: PhoneNumberAuthenticationService.INTERNAL_ERROR.PHONE_NUMBER_NOT_PROVIDED_FOR_VALIDATION.CODE,
          message:
            PhoneNumberAuthenticationService.INTERNAL_ERROR.PHONE_NUMBER_NOT_PROVIDED_FOR_VALIDATION.MSG,
        });
      }

      const phoneExists = await this.doesPhoneExistsInDatabase(phoneNumber);

      if (!phoneExists && throwOn === PhoneNumberAuthenticationService.THROW_ON.USER_NOT_FOUND) {
        throw new ValidationError({
          code: PhoneNumberAuthenticationService.VALIDATION_ERROR.USER_NOT_FOUND_IN_DB.CODE,
          message: PhoneNumberAuthenticationService.VALIDATION_ERROR.USER_NOT_FOUND_IN_DB.MSG,
        });
      }

      if (phoneExists && throwOn === PhoneNumberAuthenticationService.THROW_ON.USER_ALREADY_EXISTS) {
        throw new ValidationError({
          code: PhoneNumberAuthenticationService.VALIDATION_ERROR.USER_ALREADY_EXISTS_IN_DB.CODE,
          message: PhoneNumberAuthenticationService.VALIDATION_ERROR.USER_ALREADY_EXISTS_IN_DB.MSG,
        });
      }
    } catch (error: any) {

      this.handleIsNotError(error);

      this.tracking.logServiceError({error, apolloErrors: PhoneNumberAuthenticationService.APOLLO_ERROR});
      throw error;
    }
  }

  async sendVerificationMechanism({
    identifier: phoneNumber,
    operation,
  }: Required<SendVerificationMechanismProps>) {
    try {
      if (!fbAuth) {
        throw new InternalError({
          code: PhoneNumberAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.CODE,
          message: PhoneNumberAuthenticationService.INTERNAL_ERROR.FB_AUTH_NOT_FOUND.MSG,
        });
      }

      if (!phoneNumber) {
        throw new InternalError({
          code: PhoneNumberAuthenticationService.INTERNAL_ERROR.PHONE_NUMBER_NOT_PROVIDED_SEND.CODE,
          message: PhoneNumberAuthenticationService.INTERNAL_ERROR.PHONE_NUMBER_NOT_PROVIDED_SEND.MSG,
        });
      }

      fbAuth.useDeviceLanguage();
      const verifier = new RecaptchaVerifier(fbAuth, 'recaptcha-container', {size: 'invisible'});

      if (operation === PhoneNumberAuthenticationService.OPERATION.LINK) {
        window.confirmationResult = await linkWithPhoneNumber(fbAuth.currentUser!, phoneNumber, verifier);
      } else {
        window.confirmationResult = await signInWithPhoneNumber(fbAuth, phoneNumber, verifier);
      }
    } catch (error: unknown) {
      this.tracking.logServiceError({error, apolloErrors: PhoneNumberAuthenticationService.APOLLO_ERROR});
      throw error;
    }
  }

  // STEP 3
  async validateVerificationMechanism({code}: Pick<ValidateVerificationMechanismProps, 'code'>) {
    try {
      await window.confirmationResult?.confirm(code);
    } catch (error: any) {
      this.tracking.logServiceError({error, apolloErrors: PhoneNumberAuthenticationService.APOLLO_ERROR});
      throw error;
    }
  }
}
