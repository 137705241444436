import Link from 'next/link';

import {Badge, BadgeProps} from 'src/atoms/Badge/Badge';
import {Icon} from 'src/atoms/Icon/Icon';
import {Typography} from 'src/atoms/Typography/Typography';

import type {AllHTMLAttributes, HTMLAttributes, MouseEvent as ReactMouseEvent, ReactNode} from 'react';
import type {IconType} from 'src/lib/types';

import {
  StyledBadgesContainer,
  StyledContentWrapper,
  StyledList,
  StyledListItem,
  StyledRightContent,
  StyledWrapper,
} from './styled';

export interface ActionListProps {
  actions: Array<ActionListItemProps>;
}

export interface ActionListItemProps {
  id: string;
  title: string;
  description?: string;
  icon: IconType;
  available?: boolean;
  link?: {
    href?: string;
    rel?: HTMLAttributes<HTMLLinkElement>['rel'];
    target?: AllHTMLAttributes<HTMLLinkElement>['target'];
  };
  onClick?: (e?: ReactMouseEvent) => void;
  badges?: Array<BadgeProps>;
  rightContent?: ReactNode | string;
}

const RenderWrapper = ({
  link,
  onClick,
  title,
  id,
  children,
}: {
  link: ActionListItemProps['link'];
  onClick: ActionListItemProps['onClick'];
  title: ActionListItemProps['title'];
  id: ActionListItemProps['id'];
  rightContent: ActionListItemProps['rightContent'];
  children: ReactNode;
}) => {
  if (link?.href) {
    return (
      <Link {...link} href={link.href}>
        <StyledWrapper as="span" data-testid={`action-${id}-wrapper`}>
          {children}
        </StyledWrapper>
      </Link>
    );
  } else {
    return (
      <StyledWrapper onClick={onClick} aria-label={title} data-testid={`action-${id}-wrapper`}>
        {children}
      </StyledWrapper>
    );
  }
};

const ActionList = ({actions = [], ...props}: ActionListProps) => {
  if (actions.length === 0) {
    return null;
  }

  return (
    <StyledList {...props}>
      {actions.map(
        ({
          id = '',
          title = '',
          description = '',
          icon = {
            type: 'kastaicon',
            name: '',
          },
          link = {},
          onClick = () => null,
          available = true,
          badges = [],
          rightContent = '',
        }: ActionListItemProps) => (
          <StyledListItem key={id} $disabled={!available}>
            <RenderWrapper link={link} onClick={onClick} title={title} id={id} rightContent={rightContent}>
              {icon.name !== '' && <Icon {...icon} size={24} />}

              <StyledContentWrapper>
                <Typography as="h6" variant="subtitle2" color="var(--colors-text-onBackgroundMedium)">
                  {title}
                </Typography>
                <Typography as="p" variant="caption1" color="var(--colors-text-onBackgroundLight)">
                  {description}
                </Typography>
                {badges.length > 0 && (
                  <StyledBadgesContainer>
                    {badges.map((badge: BadgeProps) => (
                      <Badge
                        key={badge?.children?.toString() || `${new Date().getTime()}-${badge?.status}`}
                        {...badge}>
                        {badge?.children}
                      </Badge>
                    ))}
                  </StyledBadgesContainer>
                )}
              </StyledContentWrapper>

              <StyledRightContent>
                {!available ? (
                  <small>Unavailable</small>
                ) : rightContent ? (
                  <>{rightContent}</>
                ) : (
                  <Icon name="chevron-right" size={24} />
                )}
              </StyledRightContent>
            </RenderWrapper>
          </StyledListItem>
        ),
      )}
    </StyledList>
  );
};

export {ActionList};
