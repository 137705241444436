import colors from '@kasta-io/tokens/primitives/colors.json';

export const WHITE_COLOR = colors.background.paper;
export const PRIMARY_COLOR = colors.main.primary;
export const SECONDARY_COLOR = colors.main.secondary;
export const ACCENT_COLOR = colors.main.accent;
export const ERROR_COLOR = colors.states.error;
export const TEXT_BG_MEDIUM_COLOR = colors.text.onBackground;
export const TEXT_COLOR = colors.text.onBackgroundMedium;
export const TEXT_LIGHT_COLOR = colors.text.onBackgroundLight;
export const DISABLED = colors.neutral['100'];

export const BGCOLORS = {
  WHITE: colors.background.paper,
  PRIMARY: colors.main.primary,
  SECONDARY: colors.main.secondary,
  TEXT: colors.text.onBackground,
  ACCENT: colors.main.accent,
  BACKGROUND: colors.background.default,
  NEUTRAL: colors.neutral['50'],
} as const;

export const BGCOLORS_INVERTED = {
  '#FFFFFF': 'WHITE',
  '#631FFE': 'PRIMARY',
  '#01D5BF': 'SECONDARY',
  '#180840': 'TEXT',
  '#FFE35E': 'ACCENT',
  '#F6F6F6': 'BACKGROUND',
  '#E8E6EC': 'NEUTRAL',
} as const;

export const TIERS_BG_COLORS = {
  0: 'WHITE',
  1: 'SECONDARY',
  2: 'SECONDARY',
  3: 'SECONDARY',
  4: 'ACCENT',
  5: 'ACCENT',
  6: 'ACCENT',
  7: 'PRIMARY',
  8: 'PRIMARY',
  9: 'PRIMARY',
  10: 'TEXT',
} as const;
