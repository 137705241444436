import {DefaultTransition} from 'src/styled/transitions';
import styled, {css} from 'styled-components';

interface ListItemProps {
  $disabled: boolean;
}

const StyledContentWrapper = styled.div`
  flex: 1;
`;

const StyledWrapper = styled.button`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 16px 8px;
  text-align: left;
  cursor: pointer;

  [role='icon'] ~ ${StyledContentWrapper}, ${StyledContentWrapper} ~ [role='icon'],
  ${StyledContentWrapper} ~ small {
    margin-left: 16px;
  }

  small {
    color: var(--colors-neutral-100);
  }
`;

const StyledList = styled.ul`
  position: relative;
`;

const StyledListItem = styled.li<ListItemProps>`
  background-color: var(--colors-text-onPrimary);
  border-bottom: 1px solid var(--colors-background-divider);
  ${DefaultTransition()};

  &:last-child {
    border-bottom: none;
  }

  ${StyledWrapper} {
    [role='icon'] {
      color: ${({$disabled}: ListItemProps) =>
        $disabled ? 'var(--colors-neutral-100)' : 'var(--colors-primary-500)'};
    }
  }

  ${({$disabled}: ListItemProps) =>
    $disabled
      ? css`
          ${StyledWrapper} {
            pointer-events: none;

            ${StyledContentWrapper} * {
              color: var(--colors-neutral-100);
            }
          }
        `
      : css`
          &:hover {
            background-color: var(--colors-primary-25);
          }
        `}
`;

const StyledBadgesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 4px;
`;

const StyledRightContent = styled.div`
  margin-left: 16px;
`;

export {
  StyledList,
  StyledListItem,
  StyledWrapper,
  StyledContentWrapper,
  StyledRightContent,
  StyledBadgesContainer,
};
