import {useMemo, useState} from 'react';

import {Input} from 'src/atoms/Input/Input';
import {StyledAssistiveText, StyledLabel} from 'src/atoms/Input/styled';
import {IntRange} from 'type-fest';

import type {InputProps} from 'src/atoms/Input/Input';

import {StyledContainer, StyledCurrencyField} from './styled';

export interface CurrencyFormatterOptions {
  locale?: string;
  formatterOptions?: Intl.NumberFormatOptions;
}

export interface CurrencyFieldProps extends InputProps, CurrencyFormatterOptions {
  label?: string;
  floatingLabel?: boolean;
  spacing?: IntRange<0, 100>;
  value?: number;
  allowDecimals?: boolean;
  allowNegative?: boolean;
  prefix?: string;
  suffix?: string;
  step?: number;
  decimalSeparator?: string;
  groupSeparator?: string;
}

const currencyFormatter = ({locale, formatterOptions}: CurrencyFormatterOptions) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    ...formatterOptions,
  });

const CurrencyField = ({
  label = '',
  floatingLabel = true,
  spacing = 0,
  locale = 'en-US',
  formatterOptions = {
    currency: 'USD',
    maximumFractionDigits: 2,
    useGrouping: true,
    currencyDisplay: 'symbol',
  },
  ...props
}: CurrencyFieldProps) => {
  const [inputValue, setInputValue] = useState(props?.defaultValue || '');

  const options = useMemo(
    () => ({
      ...formatterOptions,
      minimumFractionDigits:
        formatterOptions?.maximumFractionDigits || formatterOptions?.minimumFractionDigits || 2,
      maximumFractionDigits:
        formatterOptions?.minimumFractionDigits || formatterOptions?.maximumFractionDigits || 2,
    }),
    [formatterOptions],
  );

  const formatCurrency = (value: string): string => {
    const number = parseInt(value.replace(/[^0-9]/g, ''), 10);
    const formatted = currencyFormatter({locale, formatterOptions: options}).format(number / 100);

    return !isNaN(number) ? formatted : '';
  };

  return (
    <StyledContainer $spacing={spacing}>
      <StyledCurrencyField>
        {label !== '' && !floatingLabel && (
          <StyledLabel htmlFor={props.name} $floating={floatingLabel}>
            {label}
          </StyledLabel>
        )}

        <Input
          type="text"
          inputMode="decimal"
          floatingLabel={floatingLabel}
          value={inputValue}
          onInput={({target}) => setInputValue(formatCurrency(target.value))}
          {...props}
        />

        {label !== '' && floatingLabel && (
          <StyledLabel $floating={floatingLabel} data-floating={true}>
            {label}
          </StyledLabel>
        )}
      </StyledCurrencyField>

      {props?.error && <StyledAssistiveText>{props.error}</StyledAssistiveText>}
    </StyledContainer>
  );
};

export {CurrencyField};
