import {createContext, useContext, useEffect, useMemo, useState} from 'react';

import {AppConfig} from '@/types/appConfig';

import {afterGet} from '@/controllers/appJsonConfig';
import {getAppJsonConfig} from '@/graphql/queries';
import {useQuery} from '@apollo/client';

import {cashExplainerViewed, tiersExplainerViewed} from '@/constants';
import {useUser} from '@/contexts/UserContext';
import {useNavigationEvent} from '@/hooks/useNavigationEvent';
import {isCashAccountActivated} from '@/utils/cash';

import type {IconType} from '@kasta-io/components';
import type {ReactNode} from 'react';

import {useComplianceContext} from './ComplianceContext';

export type RouteObj = {
  id: string;
  name: string;
  icon: IconType;
  label: string;
  mobileNavbar: boolean;
  isHighlighted?: boolean;
};

type AppConfigContextType = {
  appConfig: AppConfig;
  enabledRoutes: Array<RouteObj>;
  enabledRoutesForNewUi: Array<RouteObj>;
  loadingAppConfig: boolean;
  refetchAppConfig: Function;
};

export const AppConfigContext = createContext<AppConfigContextType>({
  appConfig: {} as AppConfig,
  enabledRoutes: [],
  enabledRoutesForNewUi: [],
  loadingAppConfig: false,
  refetchAppConfig: () => null,
});

export const useAppConfig = () => useContext(AppConfigContext);

const AppConfigContextProvider = ({children}: {children: ReactNode}) => {
  const {fbUser, user, isEEAUser} = useUser();
  const {canSkipKyc} = useComplianceContext();
  const userId = useMemo(() => user?.id, [user?.id]);

  const {
    data: appConfigQuery,
    loading,
    refetch: refetchAppConfig,
  } = useQuery(getAppJsonConfig, {
    // We need a token to call this query, even if it's from an anonymous user
    skip: !fbUser,
    fetchPolicy: 'cache-first',
  });

  // @ts-ignore
  const extractedData = useMemo(() => afterGet(appConfigQuery), [appConfigQuery]);
  const {swapEnabledPwa, tiersEnabledPwa, cashEnabledPwa} = extractedData;
  const isCashEnabled = cashEnabledPwa;
  const isCashActivated = isCashAccountActivated(user?.cashAccountStatus);

  const [isTiersExplainerViewed, setIsTiersExplainerViewed] = useState(false);

  // Runs each time route has changed
  useNavigationEvent(() => {
    const _isTiersExplainerViewed = localStorage.getItem(tiersExplainerViewed) || 'false';
    setIsTiersExplainerViewed(JSON.parse(_isTiersExplainerViewed));
  });

  const enabledRoutes = [
    {
      name: '/home',
      icon: {
        type: 'kastaicon',
        name: 'home',
      },
      label: 'Home',
      id: 'navbar-home',
    },
    {
      name: '/portfolio',
      icon: {
        type: 'kastaicon',
        name: 'portfolio',
      },
      label: 'Portfolio',
      id: 'navbar-portfolio',
    },
    isCashEnabled && {
      name: '/cash',
      icon: {
        type: 'kastaicon',
        name: 'cash-account-2',
      },
      label: 'Cash',
      id: 'navbar-cash',
    },
    swapEnabledPwa && {
      name: '/convert',
      icon: {
        type: 'kastaicon',
        name: 'swap',
      },
      label: 'Convert',
      id: 'navbar-convert',
    },
    tiersEnabledPwa && {
      name: !canSkipKyc ? '/kyc/explained' : isTiersExplainerViewed ? '/tiers' : '/tiers/explainer',
      icon: {
        type: 'kastaicon',
        name: 'kasta',
      },
      label: 'Tiers',
      id: 'navbar-tiers',
    },
  ].filter(Boolean);

  const enabledRoutesForNewUi = [
    {
      icon: {
        type: 'kastaicon',
        name: 'switch',
      },
      label: 'Quick Actions',
      id: 'navbar-quick-actions',
      isHighlighted: true,
    },
    {
      name: '/home',
      icon: {
        type: 'kastaicon',
        name: 'home',
      },
      label: 'Home',
      id: 'navbar-home',
    },
    isEEAUser &&
      isCashEnabled && {
        name: '/cash',
        icon: {
          type: 'kastaicon',
          name: 'credit-card',
        },
        label: 'Cash',
        id: 'navbar-cash',
        isHighlighted: false,
      },
    !isEEAUser && {
      name: '/explore',
      icon: {
        type: 'kastaicon',
        name: 'transfer',
      },
      label: 'Trade',
      id: 'navbar-trade',
      isHighlighted: false,
    },
    {
      name: '/portfolio',
      icon: {
        type: 'kastaicon',
        name: 'portfolio',
      },
      label: 'Portfolio',
      id: 'navbar-portfolio',
      isHighlighted: false,
    },
    {
      name: '/settings',
      icon: {
        type: 'kastaicon',
        name: 'user',
      },
      label: 'Account',
      id: 'navbar-account',
      isHighlighted: false,
    },
  ].filter(Boolean);

  useEffect(() => {
    if (!fbUser) return;
    refetchAppConfig();
  }, [fbUser, userId, refetchAppConfig]);

  return (
    <AppConfigContext.Provider
      value={{
        appConfig: extractedData,
        enabledRoutes,
        enabledRoutesForNewUi,
        loadingAppConfig: loading,
        refetchAppConfig,
      }}>
      {children}
    </AppConfigContext.Provider>
  );
};

export default AppConfigContextProvider;
