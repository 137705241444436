import {createContext, useCallback, useContext, useEffect, useState} from 'react';

import {usePopUp} from 'src/hooks/usePopUp';
import {PopUp} from 'src/organisms/PopUp/PopUp';

import type {ReactNode} from 'react';
import type {PopUpProps} from 'src/organisms/PopUp/PopUp';

interface PopUpProviderProps {
  children: ReactNode;
}

export interface PopUpContextProps {
  setPopUp: (a: PopUpProps) => void;
  hidePopUp: () => void;
  isPopUpVisible?: boolean;
}

const PopUpContext = createContext<PopUpContextProps>({
  setPopUp: () => null,
  hidePopUp: () => null,
  isPopUpVisible: false,
});

const PopUpProvider = ({children}: PopUpProviderProps) => {
  const [popUpToDisplay, setPopUpToDisplay] = useState<PopUpProps | null>(null);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);
  const {toggleBodyScroll} = usePopUp({blockScroll: !!popUpToDisplay?.$blockScroll});

  const setPopUp = useCallback((config: PopUpProps | null) => {
    if (config) {
      setPopUpToDisplay(config);
      setIsPopUpVisible(true);
      toggleBodyScroll(!!config.$blockScroll);
    }
  }, []);

  const hidePopUp = useCallback(() => {
    setIsPopUpVisible(false);
    setPopUpToDisplay(null);
    toggleBodyScroll(false);
  }, []);

  // This will hide the dialog when user presses the Back Button from the browser
  useEffect(() => {
    window.addEventListener('popstate', hidePopUp);
    return () => {
      window.removeEventListener('popstate', hidePopUp);
    };
  }, [hidePopUp]);

  return (
    <PopUpContext.Provider
      value={{
        setPopUp,
        hidePopUp,
        isPopUpVisible,
      }}>
      {popUpToDisplay && (
        <PopUp
          {...popUpToDisplay}
          open={isPopUpVisible}
          onClose={() => {
            popUpToDisplay?.onClose && popUpToDisplay.onClose();
            hidePopUp();
          }}
        />
      )}

      {children}
    </PopUpContext.Provider>
  );
};

const usePopUpContext = () => useContext(PopUpContext);

export {PopUpProvider, PopUpContext, usePopUpContext};
