import {useRef} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {ALERT_ANIMATION_TIMEOUT} from 'src/lib/constants';

import {Alert, AlertProps} from './Alert';
import {StyledContainer} from './styled';

export interface AlertContainerProps {
  alerts: Array<AlertProps>;
}

const AlertContainer = ({alerts = [], ...props}: AlertContainerProps) => {
  const nodeRef = useRef(null);
  return (
    <TransitionGroup className="alert-container">
      <StyledContainer {...props}>
        {alerts.map(({id = 'alert-0', text = '', cta = null, ...props}: AlertProps) => (
          <CSSTransition
            key={id}
            in={true}
            timeout={ALERT_ANIMATION_TIMEOUT}
            classNames="alert"
            unmountOnExit
            appear
            enter
            ref={nodeRef}>
            <Alert key={id} id={id} text={text} cta={cta} {...props} />
          </CSSTransition>
        ))}
      </StyledContainer>
    </TransitionGroup>
  );
};

export {AlertContainer};
