import {forwardRef} from 'react';

import type {FormEvent, InputHTMLAttributes} from 'react';

import {StyledCheckbox} from './styled';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id?: string;
  disabled?: boolean;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({name = '', id = '', disabled = false, ...props}, ref) => (
    <StyledCheckbox ref={ref} type="checkbox" name={name} id={id} disabled={disabled} {...props} />
  ),
);

export {Checkbox};
