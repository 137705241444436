import {Button, PopUp, Typography} from '@kasta-io/components';

import {handleContactSupport} from '@/utils/support';

interface Props {
  isVisible: boolean;
  togglePopUp: () => void;
}

export const KycUnsuccessfulDialog = ({togglePopUp, isVisible}: Props) => {
  const onContactSupport = () => {
    togglePopUp();
    handleContactSupport();
  };

  return (
    <PopUp
      open={isVisible}
      popOver
      onClose={togglePopUp}
      closeOnBackdropClick
      hasCloseButton={false}
      title="Verification Unsuccessful"
      icon={{
        type: 'custom',
        name: 'yellow-random-column',
      }}>
      <Typography variant="body2" color="var(--colors-text-onBackgroundMedium)">
        We couldn&apos;t complete your verification. For assistance, please contact support to resolve the
        issue.
      </Typography>

      <Button onClick={onContactSupport}>Contact support</Button>
    </PopUp>
  );
};
