import {forwardRef, useState} from 'react';

import {INPUT_DEBOUNCE_TIMEOUT, INPUT_FILLED_CLASS} from 'src/lib/constants';
import {useDebounce} from 'use-debounce';

import type {ChangeEvent, FocusEvent, InputHTMLAttributes, MouseEvent as ReactMouseEvent} from 'react';

import {StyledInput} from './styled';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' | 'url' | 'file' | 'hidden';
  placeholder?: string;
  pattern?: string;
  name: string;
  disabled?: boolean;
  error?: string;
  bold?: boolean;
  as?: keyof JSX.IntrinsicElements;
  floatingLabel?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onClick?: (e: ReactMouseEvent) => void;
  onInput?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      name = '',
      disabled = false,
      error = '',
      bold = false,
      floatingLabel = false,
      onChange = () => null,
      onBlur = () => null,
      onFocus = () => null,
      onClick = () => null,
      onInput = () => null,
      ...props
    },
    ref,
  ) => {
    const [inputClassName, setInputClassName] = useState<string>(
      props.defaultValue ? INPUT_FILLED_CLASS : '',
    );
    const [debouncedClassName] = useDebounce(inputClassName, INPUT_DEBOUNCE_TIMEOUT);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {target} = event;
      const {value} = target;

      if (value.length > 0) {
        setInputClassName(INPUT_FILLED_CLASS);
      } else {
        setInputClassName('');
      }

      return onChange(event);
    };

    return (
      <StyledInput
        $error={Boolean(error)}
        $bold={bold}
        ref={ref}
        type={type}
        name={name}
        id={name}
        disabled={disabled}
        className={debouncedClassName}
        $floatingLabel={floatingLabel}
        onChange={handleOnChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onInput={onInput}
        {...props}
      />
    );
  },
);

export {Input};
