/**
 * Some documentation about KYC to give you more context about this features
 * https://kasta.atlassian.net/wiki/spaces/KA/pages/620953635/Compliance
 */
import {createContext, useCallback, useContext, useMemo, useState} from 'react';

import {KycStatus} from '@/graphql/__generated__/graphql';
import {usePopUp} from '@kasta-io/components';

import {afterGetUserComplianceSettings} from '@/controllers/userComplianceSettings';
import {getUserComplianceSettings} from '@/graphql/queries';
import {ApolloQueryResult, useQuery} from '@apollo/client';

import {KycLevel} from '@/constants';
import {useAppConfig} from '@/contexts/AppConfigContext';
import {useUser} from '@/contexts/UserContext';
import {
  isCashAccountActivated,
  isCashAccountBlocked,
  isCashAccountUnderReview,
  isCashAccountVerificationNotYetUnderReview,
} from '@/utils/cash';
import {getCurrentKycFlow, getNextKycAvailableFlow} from '@/utils/compliance';

import {KycActionNeededDialog} from '@/components/kyc/KycActionNeededDialog/KycActionNeededDialog';
import {KycStartVerificationDialog} from '@/components/kyc/KycStartVerificationDialog/KycStartVerificationDialog';
import {KycUnsuccessfulDialog} from '@/components/kyc/KycUnsuccessfulDialog/KycUnsuccessfulDialog';
import {KycVerificationInProgressDialog} from '@/components/kyc/KycVerificationInProgressDialog/KycVerificationInProgressDialog';

import type {
  GetUserComplianceSettingsQuery,
  GetUserComplianceSettingsQueryVariables,
} from '@/graphql/__generated__/graphql';
import type {ComplianceInfoMap, KycSettingsInfo} from '@/types/compliance';
import type {ReactNode} from 'react';

type ComplianceContextType = {
  isUserCryptoKycSuccesful: boolean;
  isUserCryptoKycUnstarted: boolean;
  createCryptoKycBarrier: (callbackFn: () => void) => (_?: any) => void;
  createCashKycBarrier: (callbackFn: () => void) => (_?: any) => void;
  complianceSettings: ComplianceInfoMap | null;
  currentKycInfo: KycSettingsInfo;
  nextKycInfo: KycSettingsInfo;
  canSkipKyc: boolean;
  refetchComplianceSettings:
    | (() => Promise<ApolloQueryResult<GetUserComplianceSettingsQuery>>)
    | (() => null);
};

const ComplianceContext = createContext<ComplianceContextType>({
  isUserCryptoKycSuccesful: false,
  isUserCryptoKycUnstarted: false,
  createCryptoKycBarrier: callbackFn => () => callbackFn(),
  createCashKycBarrier: callbackFn => () => callbackFn(),
  complianceSettings: null,
  currentKycInfo: undefined,
  nextKycInfo: undefined,
  canSkipKyc: true,
  refetchComplianceSettings: () => null,
});

export const useComplianceContext = () => useContext(ComplianceContext);

const ComplianceContextProvider = ({children}: {children: ReactNode}) => {
  const {user, isEEAUser} = useUser();
  const {appConfig} = useAppConfig();
  const {data: userComplianceSettingsQuery, refetch} = useQuery<
    GetUserComplianceSettingsQuery,
    GetUserComplianceSettingsQueryVariables
  >(getUserComplianceSettings, {skip: !user});

  const complianceSettings = useMemo(
    () => afterGetUserComplianceSettings(userComplianceSettingsQuery),
    [userComplianceSettingsQuery],
  );
  const currentKycInfo = useMemo(
    () => (user && complianceSettings ? getCurrentKycFlow(complianceSettings, user) : undefined),
    [complianceSettings, user],
  );
  const nextKycInfo = useMemo(
    () => (user && complianceSettings ? getNextKycAvailableFlow(complianceSettings, user) : undefined),
    [complianceSettings, user],
  );

  // User can skip KYC if it has completed at least one KYC
  const canSkipKyc = useMemo(() => {
    if (!user || !user?.kyc) {
      return false;
    }

    // Allow testing user only to ROW users
    if (
      !appConfig?.kycAuthorizerRowRestrictionEnabled &&
      [KycLevel.ROW0, KycLevel.ROW1].includes(user?.kyc?.level as KycLevel)
    ) {
      return true;
    }

    /**
     * Force KYC for RED_RETRY submission
     */
    if (user?.kyc?.status === KycStatus.RedRetry || user?.kyc?.pending?.status === KycStatus.RedRetry) {
      return false;
    }

    if (user?.kyc?.history?.length) {
      /**
       * In case a user has a reject KYC history we should force KYC until the last level is approved
       * this means nextKycInfo null and user.kyc.level === GREEN
       */
      if (!nextKycInfo && user.kyc.status === KycStatus.Green) {
        return true;
      }
      for (const history of user.kyc.history) {
        if (history?.status === KycStatus.RedRetry || history?.rejectReason) {
          return false;
        }
      }
    }

    // Allow  OLD EEA, ROW Level 2 and EEA user with GREEN status
    if (
      [KycLevel.One, KycLevel.EEA0, KycLevel.ROW2].includes(user.kyc.level as KycLevel) &&
      user?.kyc?.status === KycStatus.Green
    ) {
      return true;
    }

    /**
     * If user has no KYC history but a GREEN KYC status
     * it means user did row-0 flow and they can skip KYC
     * Otherwise, we force user to do KYC
     */
    return false;
  }, [user, nextKycInfo, appConfig?.kycAuthorizerRowRestrictionEnabled]);

  const [kycType, setKycType] = useState<'cash' | 'crypto'>('crypto');

  const {isVisible: isKycStartVerificationDialogVisible, togglePopUp: toggleKycStartVerificationDialog} =
    usePopUp({
      blockScroll: false,
    });

  const {isVisible: isKycActionNeededDialogVisible, togglePopUp: toggleKycActionNeededDialog} = usePopUp({
    blockScroll: false,
  });

  const {isVisible: isKycUnsucessfulDialogVisible, togglePopUp: toggleKycUnsucessfulDialog} = usePopUp({
    blockScroll: false,
  });

  const {
    isVisible: isKycVerificationInProgressDialogVisible,
    togglePopUp: toggleKycVerificationInProgressDialog,
  } = usePopUp({
    blockScroll: false,
  });

  const createCryptoKycBarrier = useCallback(
    (callbackFn: () => void) => (_: any) => {
      // EEA Crypto KYC Barriers
      const hasSuccessfullyFinalizedEeaCryptoKyc =
        user?.kyc?.level &&
        [KycLevel.EEA0, KycLevel.One].includes(user?.kyc?.level as KycLevel) &&
        user?.kyc?.status === KycStatus.Green;

      if (isEEAUser && !hasSuccessfullyFinalizedEeaCryptoKyc) {
        setKycType('crypto');

        switch (user?.kyc?.status) {
          case KycStatus.Unsubmitted:
          case KycStatus.Created:
            return toggleKycStartVerificationDialog();
          case KycStatus.Pending:
            return toggleKycVerificationInProgressDialog();
          case KycStatus.RedRetry:
            return toggleKycActionNeededDialog();
          case KycStatus.RedFinal:
          case KycStatus.OnHold:
            return toggleKycUnsucessfulDialog();
          default:
            // Will prompt user to contact support in unexpected situations
            return toggleKycUnsucessfulDialog();
        }
      }

      // ROW Crypto KYC Barriers
      const hasSuccessfullyFinalizedRowKyc =
        user?.kyc?.level &&
        [KycLevel.ROW1, KycLevel.ROW2, KycLevel.One].includes(user?.kyc?.level as KycLevel) &&
        user?.kyc?.status === KycStatus.Green;

      if (!isEEAUser && !hasSuccessfullyFinalizedRowKyc) {
        setKycType('crypto');

        if (user?.kyc?.level === KycLevel.ROW0) {
          switch (user?.kyc?.pending?.status) {
            case undefined:
            case KycStatus.Created:
              return toggleKycStartVerificationDialog();
            case KycStatus.Pending:
              return toggleKycVerificationInProgressDialog();
            case KycStatus.RedRetry:
              return toggleKycActionNeededDialog();
            case KycStatus.RedFinal:
            case KycStatus.OnHold:
              return toggleKycUnsucessfulDialog();
            default:
              // Will prompt user to contact support in unexpected situations
              return toggleKycUnsucessfulDialog();
          }
        }
      }

      callbackFn();
    },
    [
      isEEAUser,
      toggleKycActionNeededDialog,
      toggleKycStartVerificationDialog,
      toggleKycUnsucessfulDialog,
      toggleKycVerificationInProgressDialog,
      user?.kyc?.level,
      user?.kyc?.pending?.status,
      user?.kyc?.status,
    ],
  );

  const createCashKycBarrier = useCallback(
    (callbackFn: () => void) => (_: any) => {
      // EEA Cash KYC Barriers
      const hasSuccessfullyFinalizedREeaCashKyc =
        user?.kyc?.status === KycStatus.Green && isCashAccountActivated(user.cashAccountStatus);

      if (isEEAUser && !hasSuccessfullyFinalizedREeaCashKyc) {
        setKycType('cash');

        if (isCashAccountVerificationNotYetUnderReview(user?.cashAccountStatus)) {
          return toggleKycStartVerificationDialog();
        }

        if (isCashAccountUnderReview(user?.cashAccountStatus)) {
          return toggleKycVerificationInProgressDialog();
        }

        if (isCashAccountBlocked(user?.cashAccountStatus)) {
          return toggleKycUnsucessfulDialog();
        }

        // Will prompt user to contact support in unexpected situations
        toggleKycUnsucessfulDialog();
      }

      callbackFn();
    },
    [
      isEEAUser,
      toggleKycStartVerificationDialog,
      toggleKycUnsucessfulDialog,
      toggleKycVerificationInProgressDialog,
      user?.cashAccountStatus,
      user?.kyc?.status,
    ],
  );

  const userKycStatus = (user?.kyc?.pending?.status || user?.kyc?.status) as KycStatus;
  const userKycLevel = (user?.kyc?.pending?.level || user?.kyc?.level) as KycLevel;

  const isUserCryptoKycUnstarted = isEEAUser
    ? userKycLevel === KycLevel.EEA0 && [KycStatus.Created, KycStatus.Unsubmitted].includes(userKycStatus)
    : (userKycLevel === KycLevel.ROW0 && userKycStatus === KycStatus.Green) ||
      (userKycLevel === KycLevel.ROW2 && userKycStatus === KycStatus.Created);

  const isUserCryptoKycSuccesful = isEEAUser
    ? userKycStatus === KycStatus.Green
    : userKycLevel !== KycLevel.ROW0 && userKycStatus === KycStatus.Green;

  return (
    <ComplianceContext.Provider
      value={{
        isUserCryptoKycSuccesful,
        isUserCryptoKycUnstarted,
        createCashKycBarrier,
        createCryptoKycBarrier,
        complianceSettings,
        currentKycInfo,
        nextKycInfo,
        canSkipKyc,
        refetchComplianceSettings: refetch,
      }}>
      {typeof document !== 'undefined' && (
        <>
          <KycUnsuccessfulDialog
            isVisible={isKycUnsucessfulDialogVisible}
            togglePopUp={toggleKycUnsucessfulDialog}
          />
          <KycActionNeededDialog
            type={kycType}
            isVisible={isKycActionNeededDialogVisible}
            togglePopUp={toggleKycActionNeededDialog}
          />
          <KycVerificationInProgressDialog
            type={kycType}
            isVisible={isKycVerificationInProgressDialogVisible}
            togglePopUp={toggleKycVerificationInProgressDialog}
          />
          <KycStartVerificationDialog
            type={kycType}
            isVisible={isKycStartVerificationDialogVisible}
            togglePopUp={toggleKycStartVerificationDialog}
          />
        </>
      )}

      {children}
    </ComplianceContext.Provider>
  );
};

export default ComplianceContextProvider;
