import {createContext, useCallback, useContext, useState} from 'react';

import {AlertContainer} from 'src/molecules/Alert/Container';

import type {ReactNode} from 'react';
import type {AlertProps} from 'src/molecules/Alert/Alert';

interface AlertProviderProps {
  children: ReactNode;
}

export interface AlertContextProps {
  addAlert: (a: AlertProps) => void;
  removeAlert: (a: string) => void;
}

const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);

const AlertProvider = ({children}: AlertProviderProps) => {
  const [alerts, setAlerts] = useState<Array<AlertProps>>([]);

  const addAlert = useCallback(
    ({id = `new Date().getTime()`, text = 'Alert message', cta = null, ...props}: AlertProps) => {
      setAlerts(alerts => [
        ...alerts,
        {
          id,
          text,
          cta,
          ...props,
        },
      ]);
    },
    [alerts, setAlerts],
  );

  const removeAlert = useCallback(
    (id: string) => {
      const list: Array<AlertProps> = [...alerts];
      const indexToRemove = list.findIndex((alert: AlertProps) => alert.id === id);
      list.splice(indexToRemove, 1);

      setAlerts(list);
    },
    [alerts, setAlerts],
  );

  return (
    <AlertContext.Provider
      value={{
        addAlert,
        removeAlert,
      }}>
      {children}
      <AlertContainer alerts={alerts} />
    </AlertContext.Provider>
  );
};

const useAlertContext = () => useContext(AlertContext);

export {AlertProvider, AlertContext, useAlertContext};
