import {createContext, useContext, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {useMountEffect} from 'primereact/hooks';

import {findLast} from '@/utils/findLast';

import type {ReactNode} from 'react';

type UserContextType = {
  enterPathname: string;
  routesHistory: string[];
  loading: Boolean;
  previousPage: string;
  lastEntryFeaturePage: string;
};

const NavigationContext = createContext<UserContextType>({
  enterPathname: '',
  routesHistory: [],
  loading: false,
  previousPage: '',
  lastEntryFeaturePage: '',
});

export const useNavigationContext = () => useContext(NavigationContext);

const NavigationContextProvider = ({children}: {children: ReactNode}) => {
  const router = useRouter();
  const {asPath} = router;
  const [enterPathname, setEnterPathname] = useState('');
  const [routesHistory, setRoutesHistory] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  useMountEffect(() => {
    setEnterPathname(asPath);
  });

  useEffect(() => {
    setRoutesHistory(prev => [...prev, asPath]);
  }, [asPath]);

  useEffect(() => {
    // Page transition START
    const start = () => {
      setLoading(true);
    };

    // Page transition END
    const end = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);

    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);
    };
  }, [router]);
  // TODO: refactor, it's getting to tricky to explicitly add one by one. Maybe define the list of "feature" and then anything else can be ignored
  const filterFeaturePages = (page: string) =>
    !(
      ['/', '/login', '/kyc', '/kyc?from=settings'].includes(page) ||
      page.startsWith('/asset-select') ||
      page.startsWith('/buy') ||
      page.startsWith('/sell') ||
      page.startsWith('/tx') ||
      page.startsWith('/cash/debit-card/order') ||
      page.startsWith('/cash/debit-card/explainer') ||
      page.startsWith('/cash/debit-card/success') ||
      page.startsWith('/cash/debit-card?processing') ||
      page.startsWith('/cash/debit-card/terminate/confirm') ||
      page.startsWith('/cash/debit-card/terminate/details') ||
      page.startsWith('/cash/debit-card/terminate/reason') ||
      page.startsWith('/settings/fees-and-limits')
    );

  const cleanUpParameters = (page: string) => {
    // Due to the Asset Selector and the Buy Flow behavior where /portfolio and /home I had to add some params
    // only for the Buy Feature to be able to redirect user or open the Buy Dialog from the screen itself
    if (page.startsWith('/home') || page.startsWith('/portfolio')) {
      return page.split('?')[0];
    }
    return page;
  };

  const historyLength = routesHistory.length;
  const previousPage =
    historyLength > 2 ? routesHistory[historyLength - 2] : routesHistory[historyLength - 1];
  // The last page that could be useful to the header go back button return to
  const filteredFeaturePages = routesHistory.filter(filterFeaturePages).map(cleanUpParameters);
  // Finds last item in the routesHistory that is not the current page or the home page

  const lastEntryFeaturePage = findLast(filteredFeaturePages, page => page !== asPath) || '/home';

  return (
    <NavigationContext.Provider
      value={{
        enterPathname,
        routesHistory,
        loading,
        previousPage,
        lastEntryFeaturePage,
      }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
