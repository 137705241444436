import {rotate} from 'src/styled/keyframes';
import {DefaultTransition} from 'src/styled/transitions';
import styled, {css} from 'styled-components';

import type {Color as ColorType, Shadow as ShadowType} from '@kasta-io/tokens/src/types';

interface ButtonTheme {
  background: string | ColorType;
  color: string | ColorType;
  borderColor: string | ColorType;
  elevation: ShadowType;
  cta?: string;
}

interface ButtonState {
  primary: {
    default: ButtonTheme;
    inverted: ButtonTheme;
  };
  'primary-icon': {
    default: ButtonTheme;
    inverted: ButtonTheme;
  };
  secondary: {
    default: ButtonTheme;
    inverted: ButtonTheme;
  };
  'secondary-icon': {
    default: ButtonTheme;
    inverted: ButtonTheme;
  };
  tertiary: {
    default: ButtonTheme;
    inverted: ButtonTheme;
  };
}

interface CTAProps {
  $type: 'primary' | 'primary-icon' | 'secondary' | 'secondary-icon' | 'tertiary';
  $invert?: boolean;
}

interface ButtonProps extends CTAProps {
  $size: 'xs' | 'small' | 'medium' | 'large' | 'xl';
  $loading?: boolean;
  disabled?: boolean | undefined;
  $block?: boolean;
  $noPadding?: boolean;
  $ghost?: boolean;
}

const sizes = {
  xs: {
    padding: '12px',
    paddingIcon: '8px',
    fontSize: 'var(--font-styles-button2-size)',
    lineHeight: 'var(--font-styles-button2-lineHeight)',
    letterSpacing: 'var(--font-styles-button2-spacing)',
    icon: '16px',
  },
  small: {
    padding: '12px 16px',
    paddingIcon: '8px',
    fontSize: 'var(--font-styles-button1-size)',
    lineHeight: 'var(--font-styles-button1-lineHeight)',
    letterSpacing: 'var(--font-styles-button1-spacing)',
    icon: '24px',
  },
  medium: {
    padding: '16px 20px',
    paddingIcon: '12px',
    fontSize: 'var(--font-styles-button1-size)',
    lineHeight: 'var(--font-styles-button1-lineHeight)',
    letterSpacing: 'var(--font-styles-button1-spacing)',
    icon: '24px',
  },
  large: {
    padding: '20px',
    paddingIcon: '16px',
    fontSize: 'var(--font-styles-button1-size)',
    lineHeight: 'var(--font-styles-button1-lineHeight)',
    letterSpacing: 'var(--font-styles-button1-spacing)',
    icon: '24px',
  },
  xl: {
    padding: '24px 20px',
    paddingIcon: '20px',
    fontSize: 'var(--font-styles-button1-size)',
    lineHeight: 'var(--font-styles-button1-lineHeight)',
    letterSpacing: 'var(--font-styles-button1-spacing)',
    icon: '24px',
  },
};

const types = {
  primary: {
    default: {
      background: 'var(--colors-primary-500)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-500)',
      elevation: 'var(--shadow--04dp)',
      cta: 'var(--colors-primary-500)',
    },
    inverted: {
      background: 'var(--colors-text-onPrimary)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-text-onPrimary)',
      elevation: 'var(--shadow--04dp)',
      cta: 'var(--colors-text-onPrimary)',
    },
  },
  'primary-icon': {
    default: {
      background: 'var(--colors-primary-500)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-500)',
      elevation: 'var(--shadow--04dp)',
      cta: 'var(--colors-primary-500)',
    },
    inverted: {
      background: 'var(--colors-text-onPrimary)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-text-onPrimary)',
      elevation: 'var(--shadow--04dp)',
      cta: 'var(--colors-text-onPrimary)',
    },
  },
  secondary: {
    default: {
      background: 'transparent',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-500)',
      elevation: 'none',
      cta: 'var(--colors-primary-500)',
    },
    inverted: {
      background: 'transparent',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-text-onPrimary)',
      elevation: 'none',
      cta: 'var(--colors-text-onPrimary)',
    },
  },
  'secondary-icon': {
    default: {
      background: 'transparent',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
      cta: 'var(--colors-primary-500)',
    },
    inverted: {
      background: 'transparent',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-50)',
      elevation: 'none',
      cta: 'var(--colors-text-onPrimary)',
    },
  },
  tertiary: {
    default: {
      background: 'transparent',
      color: 'var(--colors-primary-500)',
      borderColor: 'transparent',
      elevation: 'none',
      cta: 'var(--colors-text-onPrimary)',
    },
    inverted: {
      background: 'transparent',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'transparent',
      elevation: 'none',
      cta: 'var(--colors-primary-500)',
    },
  },
};

const hover: ButtonState = {
  primary: {
    default: {
      background: 'var(--colors-primary-300)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-300)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-200)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-200)',
      elevation: 'none',
    },
  },
  'primary-icon': {
    default: {
      background: 'var(--colors-primary-300)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-300)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-200)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-200)',
      elevation: 'none',
    },
  },
  secondary: {
    default: {
      background: 'var(--colors-primary-50)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-500)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-400)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-text-onPrimary)',
      elevation: 'none',
    },
  },
  'secondary-icon': {
    default: {
      background: 'var(--colors-primary-50)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-400)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
  },
  tertiary: {
    default: {
      background: 'var(--colors-primary-50)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-50)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-400)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-400)',
      elevation: 'none',
    },
  },
};

const pressed: ButtonState = {
  primary: {
    default: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
  },
  'primary-icon': {
    default: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
  },
  secondary: {
    default: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-500)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-200)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-text-onPrimary)',
      elevation: 'none',
    },
  },
  'secondary-icon': {
    default: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-200)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-text-onPrimary)',
      elevation: 'none',
    },
  },
  tertiary: {
    default: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-200)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-200)',
      elevation: 'none',
    },
  },
};

const disabled: ButtonState = {
  primary: {
    default: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'var(--shadow--04dp)',
    },
    inverted: {
      background: 'var(--colors-neutral-100)',
      color: 'var(--colors-primary-200)',
      borderColor: 'transparent',
      elevation: 'none',
    },
  },
  'primary-icon': {
    default: {
      background: 'var(--colors-primary-100)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'var(--shadow--04dp)',
    },
    inverted: {
      background: 'var(--colors-neutral-100)',
      color: 'var(--colors-primary-200)',
      borderColor: 'transparent',
      elevation: 'none',
    },
  },
  secondary: {
    default: {
      background: 'var(--colors-text-onPrimary)',
      color: 'var(--colors-primary-100)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
    inverted: {
      background: 'transparent',
      color: 'var(--colors-primary-200)',
      borderColor: 'var(--colors-primary-200)',
      elevation: 'none',
    },
  },
  'secondary-icon': {
    default: {
      background: 'var(--colors-text-onPrimary)',
      color: 'var(--colors-primary-100)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
    inverted: {
      background: 'transparent',
      color: 'var(--colors-primary-200)',
      borderColor: 'var(--colors-primary-200)',
      elevation: 'none',
    },
  },
  tertiary: {
    default: {
      background: 'var(--colors-text-onPrimary)',
      color: 'var(--colors-primary-100)',
      borderColor: 'transparent',
      elevation: 'none',
    },
    inverted: {
      background: 'transparent',
      color: 'var(--colors-primary-200)',
      borderColor: 'transparent',
      elevation: 'none',
    },
  },
};

const loading: ButtonState = {
  primary: {
    default: {
      background: 'var(--colors-primary-200)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-200)',
      elevation: 'var(--shadow--04dp)',
    },
    inverted: {
      background: 'var(--colors-primary-300)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'transparent',
      elevation: 'none',
    },
  },
  'primary-icon': {
    default: {
      background: 'var(--colors-primary-200)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-primary-200)',
      elevation: 'var(--shadow--04dp)',
    },
    inverted: {
      background: 'var(--colors-primary-300)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'transparent',
      elevation: 'none',
    },
  },
  secondary: {
    default: {
      background: 'var(--colors-primary-25)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-500)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-300)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-text-onPrimary)',
      elevation: 'none',
    },
  },
  'secondary-icon': {
    default: {
      background: 'var(--colors-primary-25)',
      color: 'var(--colors-primary-500)',
      borderColor: 'var(--colors-primary-100)',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-300)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'var(--colors-text-onPrimary)',
      elevation: 'none',
    },
  },
  tertiary: {
    default: {
      background: 'var(--colors-primary-25)',
      color: 'var(--colors-primary-500)',
      borderColor: 'transparent',
      elevation: 'none',
    },
    inverted: {
      background: 'var(--colors-primary-300)',
      color: 'var(--colors-text-onPrimary)',
      borderColor: 'transparent',
      elevation: 'none',
    },
  },
};

const StyledButton = styled.button<ButtonProps>`
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  gap: 12px;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  padding: ${({$size, $noPadding, $type}: ButtonProps) =>
    $noPadding ? '0' : $type.includes('icon') ? sizes[$size].paddingIcon : sizes[$size].padding};
  font-size: ${({$size}: ButtonProps) => sizes[$size].fontSize};
  font-weight: 700;
  line-height: ${({$size}: ButtonProps) => sizes[$size].lineHeight};
  letter-spacing: ${({$size}: ButtonProps) => sizes[$size].letterSpacing};
  cursor: pointer;
  outline: 2px solid;
  outline-color: ${({$ghost, $type, $invert}: ButtonProps) =>
    $ghost ? 'transparent' : types[$type][$invert ? 'inverted' : 'default'].borderColor};
  outline-offset: -2px;
  background-color: ${({$ghost, $type, $invert}: ButtonProps) =>
    $ghost ? 'transparent' : $type && types[$type][$invert ? 'inverted' : 'default'].background};
  border: none;
  border-radius: 12px;
  box-shadow: ${({$ghost, $type, $invert}: ButtonProps) =>
    $ghost ? 'none' : types[$type][$invert ? 'inverted' : 'default'].elevation};

  ${({$block}: ButtonProps) =>
    $block &&
    css`
      width: 100%;
    `}

  &,
  [role='icon'] {
    color: ${({$invert, $type}: ButtonProps) => types[$type][$invert ? 'inverted' : 'default'].color};
    ${DefaultTransition()};
  }

  ${({$ghost, $size}: ButtonProps) =>
    !$ghost &&
    css`
      [role='icon'] {
        width: ${$size && sizes[$size].icon};
        height: ${$size && sizes[$size].icon};
        font-size: ${$size && sizes[$size].icon};
      }
    `}

  &:hover {
    outline-color: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : hover[$type][$invert ? 'inverted' : 'default'].borderColor};
    background-color: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : hover[$type][$invert ? 'inverted' : 'default'].background};
    box-shadow: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : hover[$type][$invert ? 'inverted' : 'default'].elevation};

    &,
    [role='icon'] {
      color: ${({$invert, $type}: ButtonProps) => hover[$type][$invert ? 'inverted' : 'default'].color};
    }
  }

  &:active {
    outline-color: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : pressed[$type][$invert ? 'inverted' : 'default'].borderColor};
    background-color: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : pressed[$type][$invert ? 'inverted' : 'default'].background};
    box-shadow: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : pressed[$type][$invert ? 'inverted' : 'default'].elevation};

    &,
    [role='icon'] {
      color: ${({$invert, $type}: ButtonProps) => pressed[$type][$invert ? 'inverted' : 'default'].color};
    }
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
    outline-color: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : disabled[$type][$invert ? 'inverted' : 'default'].borderColor};
    background-color: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : disabled[$type][$invert ? 'inverted' : 'default'].background};
    box-shadow: ${({$ghost, $invert, $type}: ButtonProps) =>
      $ghost ? 'transparent' : disabled[$type][$invert ? 'inverted' : 'default'].elevation};

    &,
    [role='icon'] {
      color: ${props => props.$type && disabled[props.$type][props.$invert ? 'inverted' : 'default'].color};
    }
  }

  ${({$loading, $size, $type, $invert}: ButtonProps) =>
    $loading &&
    $size &&
    css`
      pointer-events: none;
      outline-color: ${loading[$type][$invert ? 'inverted' : 'default'].borderColor};
      background-color: ${loading[$type][$invert ? 'inverted' : 'default'].background};
      box-shadow: ${loading[$type][$invert ? 'inverted' : 'default'].elevation};

      &,
      [role='icon'] {
        color: ${loading[$type][$invert ? 'inverted' : 'default'].color};
      }

      &:after {
        position: relative;
        display: inline-block;
        width: ${$size === 'xs' ? '16px' : '18px'};
        height: ${$size === 'xs' ? '16px' : '18px'};
        border: 2px solid var(--colors-primary-500);
        border-left-color: var(--colors-primary-50);
        border-radius: 50%;
        animation: ${rotate} linear 1s infinite;
      }
    `}

  ${({$loading, $type}: ButtonProps) =>
    $loading &&
    $type.includes('icon') &&
    css`
      [role='icon'] {
        display: none;
      }
    `}
`;

const StyledContainer = styled.div`
  position: relative;
`;

const StyledCTA = styled.span<CTAProps>`
  display: block;
  margin-top: 6px;
  font-family: var(--font-styles-body2-family);
  font-size: var(--font-styles-body2-size);
  font-style: normal;
  font-weight: var(--font-styles-body2-weight);
  line-height: var(--font-styles-body2-lineHeight);
  color: ${({$type, $invert}: CTAProps) => $type && types[$type][$invert ? 'inverted' : 'default'].cta};
  text-align: center;
`;

export {StyledButton, StyledContainer, StyledCTA};
