{
  "name": "@kasta-io/components",
  "version": "0.31.1",
  "description": "Kasta React components",
  "main": "dist/index.js",
  "exports": {
    ".": {
      "types": "./dist/index.d.ts",
      "default": "./dist/index.js"
    }
  },
  "keywords": [],
  "publishConfig": {
    "@kasta-io:registry": "https://npm.pkg.github.com"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/kasta-io/design-system.git"
  },
  "scripts": {
    "build": "tsup",
    "dev": "tsup --watch",
    "clean": "rm -rf .turbo && rm -rf node_modules && rm -rf dist",
    "test": "jest --watchAll=false --coverage",
    "test:watch": "jest --watch",
    "test:clean": "jest --clearCache"
  },
  "files": [
    "dist"
  ],
  "devDependencies": {
    "@kasta-io/css-reset": "*",
    "@kasta-io/icons": "*",
    "@kasta-io/tokens": "*",
    "@kasta-io/ts-config": "*",
    "@testing-library/dom": "^10.4.0",
    "@testing-library/jest-dom": "^6.6.3",
    "@testing-library/react": "^16.2.0",
    "@testing-library/user-event": "^14.6.1",
    "@types/identity-obj-proxy": "^3.0.2",
    "@types/jest": "^29.5.14",
    "@types/react": "^18.3.12",
    "@types/react-dom": "^18.3.1",
    "@types/react-transition-group": "^4.4.12",
    "@types/styled-components": "^5.1.34",
    "identity-obj-proxy": "^3.0.0",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "polished": "^4.3.1",
    "qrcode.react": "^4.2.0",
    "styled-components": "^6.1.15",
    "ts-jest": "^29.2.5",
    "tsup": "^8.3.6",
    "type-fest": "^4.33.0",
    "typescript": "^5.6.3",
    "use-debounce": "^10.0.4"
  },
  "dependencies": {
    "@kasta-io/tokens": "*",
    "@react-input/mask": "^2.0.4",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "react-transition-group": "^4.4.5"
  },
  "peerDependencies": {
    "next": "^14.2.21",
    "react": "^18.3.1",
    "react-dom": "^18.3.1"
  },
  "jest": {
    "coverageThreshold": {
      "global": {
        "branches": 40,
        "functions": 70,
        "lines": 70,
        "statements": 70
      }
    },
    "coverageReporters": [
      "json-summary",
      "text",
      "lcov"
    ],
    "coverageDirectory": "<rootDir>/coverage/",
    "preset": "ts-jest",
    "testEnvironment": "jsdom",
    "setupFilesAfterEnv": [
      "<rootDir>/setupTests.tsx"
    ],
    "moduleDirectories": [
      "node_modules",
      "src"
    ],
    "moduleNameMapper": {
      ".+\\.(css|styl|less|sass|scss|png|jpg|svg|ttf|woff|woff2)$": "identity-obj-proxy",
      "src/(.*)$": "<rootDir>/src/$1"
    }
  }
}
