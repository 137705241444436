import {gql} from './__generated__/gql';

export const getUser = gql(/* GraphQL */ `
  query GetUser {
    getUser {
      id
      firstName
      lastName
      email
      cashAccountStatus
      emailVerified
      phoneNumber
      kastaName
      telegramName
      address {
        street
        state
        subStreet
        aptNumber
        city
        postalCode
        country
        buildingNumber
      }
      referrerCode
      account {
        balances {
          EUR
          BTC
          KASTA
          ETH
          USDT
          POL
          SOL
          USDC
          BNB
          BUSD
          ADA
          DOT
        }
        currencyBalances {
          currency
          available
          incoming
          reserved
          lockedUp
        }
        createdAt
        updatedAt
        userId
      }
      kyc {
        status
        level
        rejectReason
        pending {
          status
          level
          rejectReason
        }
        history {
          status
          level
          rejectReason
          updatedAt
        }
      }
      twoFactorAuth {
        isEnabled
        createdAt
        disabledAt
      }
      deviceToken
      deviceAppInstanceId
      deviceType
      createdAt
      updatedAt
      marketingOptIn
    }
  }
`);

export const getTransaction = gql(/* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      parentId
      counterPartyUser {
        id
        firstName
        lastName
        kastaName
        cashAccountStatus
      }
      type
      subtype
      entryType
      status
      source {
        type
        id
      }
      dest {
        type
        id
      }
      sourceCurrency
      sourceAmount
      destCurrency
      destAmount
      fees
      createdAt
      exchangeRate
      details
      usdExchangeRate
      sourceCurrencyUsdExchangeRate
      destCurrencyUsdExchangeRate
    }
  }
`);

export const getImprovedExchangeRates = gql(/* GraphQL */ `
  query GetImprovedExchangeRates($input: GetExchangeRatesInput!) {
    getImprovedExchangeRates(input: $input) {
      rates {
        pair
        value
        price
        turnover24h
        volume24h
        value24h
        price24h
        change24h
      }
    }
  }
`);

export const getTransactions = gql(/* GraphQL */ `
  query GetTransactions($input: GetTransactionsInput) {
    getTransactions(input: $input) {
      items {
        id
        parentId
        counterPartyUser {
          id
          firstName
          lastName
          kastaName
          cashAccountStatus
        }
        type
        subtype
        entryType
        status
        source {
          type
          id
        }
        dest {
          type
          id
        }
        sourceCurrency
        sourceAmount
        destCurrency
        destAmount
        fees
        createdAt
        exchangeRate
        details
        usdExchangeRate
        sourceCurrencyUsdExchangeRate
        destCurrencyUsdExchangeRate
        originalSourceAmount
      }
      lastKey {
        PK
        SK
        GSI1PK
      }
    }
  }
`);

export const getUserByPhone = gql(/* GraphQL */ `
  query GetUserByPhone($phone: String!) {
    getUserByPhone(phone: $phone) {
      id
      firstName
      lastName
      kastaName
      cashAccountStatus
    }
  }
`);

export const getUserByKastaName = gql(/* GraphQL */ `
  query GetUserByKastaName($kastaName: String!) {
    getUserByKastaName(kastaName: $kastaName) {
      id
      firstName
      lastName
      kastaName
      cashAccountStatus
    }
  }
`);

export const userExists = gql(/* GraphQL */ `
  query UserExists($phone: String!) {
    userExists(phone: $phone)
  }
`);

export const getKycAccessToken = gql(/* GraphQL */ `
  query GetKycAccessToken($flowName: String!) {
    getKycAccessToken(flowName: $flowName)
  }
`);

export const getSupportedBlockchainAssets = gql(/* GraphQL */ `
  query GetSupportedBlockchainAssets {
    getSupportedBlockchainAssets {
      id
      currency
      name
      addressType
      explorer
      message
      symbol
      decimalDigits
      type
    }
  }
`);

export const validatePin = gql(/* GraphQL */ `
  query ValidatePin($input: ValidatePinInput!) {
    validatePin(input: $input) {
      valid
      attemptsLeft
      timeoutEnds
    }
  }
`);

export const getUserByEmail = gql(/* GraphQL */ `
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      firstName
      lastName
      kastaName
      cashAccountStatus
    }
  }
`);

export const getUserTransferCounterparties = gql(/* GraphQL */ `
  query GetUserTransferCounterparties($limit: Int) {
    getUserTransferCounterparties(limit: $limit) {
      id
      firstName
      lastName
      kastaName
      cashAccountStatus
    }
  }
`);

export const getAppJsonConfig = gql(/* GraphQL */ `
  query getAppJsonConfig {
    getAppJsonConfig
  }
`);

export const getSwapQuote = gql(/* GraphQL */ `
  query getQuote($input: SwapQuoteInput!) {
    getSwapQuote(input: $input) {
      exchangeRate
      sourceCurrency
      sourceAmount
      fees
      destCurrency
      destAmount
      usdExchangeRate
      createdAt
    }
  }
`);

export const estimateWithdrawFee = gql(/* GraphQL */ `
  query estimateWithdrawFee($input: EstimateWithdrawFeeInput!) {
    estimateWithdrawFee(input: $input) {
      fees
    }
  }
`);

export const getTiersInfo = gql(/* GraphQL */ `
  query GetTiersInfo {
    getTiersInfo {
      exchangeRateFees
      lockUpKastaAmount
      lockUpKastaAmountPromotion
      level
      name
      promoEnds
      theme
      lockUpKastaAmount
      lockUpKastaAmountPromotion
      lockUpDays
      lockUpAPY
    }
  }
`);

export const getCurrentTierLockup = gql(/* GraphQL */ `
  query GetCurrentTierLockup {
    getCurrentTierLockup {
      archivedAt
      createdAt
      id
      kastaEarntAmount
      kastaLockupAmount
      kastaUsdExchangeRate
      kastaYieldAmount
      startDate
      startTxIds {
        platformTxId
        userTxId
      }
      status
      tierInfo {
        exchangeRateFees
        lockUpKastaAmount
        lockUpKastaAmountPromotion
        level
        name
        theme
        lockUpKastaAmount
        lockUpKastaAmountPromotion
        lockUpDays
        lockUpAPY
      }
      endDate
      updatedAt
      userId
      claimed
    }
  }
`);

export const getUserTierLockups = gql(/* GraphQL */ `
  query GetUserTierLockups($status: TierStatus) {
    getUserTierLockups(input: {status: $status}) {
      archivedAt
      createdAt
      id
      kastaEarntAmount
      kastaLockupAmount
      kastaUsdExchangeRate
      kastaYieldAmount
      startDate
      startTxIds {
        platformTxId
        userTxId
      }
      status
      tierInfo {
        earnAPY
        exchangeRateFees
        lockUpKastaAmount
        lockUpKastaAmountPromotion
        level
        name
        promoEnds
        theme
        lockUpKastaAmount
        lockUpKastaAmountPromotion
        lockUpDays
        lockUpAPY
      }
      endDate
      endTxIds {
        platformTxId
        userTxId
      }
      updatedAt
      userId
      claimed
    }
  }
`);

export const getCarousels = gql(/* GraphQL */ `
  query GetCarousels {
    getCarousels {
      canSkip
      feature
      showTo
      slides {
        animation
        backgroundColor
        backgroundImage
        backgroundImageArr {
          arr
          imageWidth
        }
        text
        title
      }
    }
  }
`);

export const getCountries = gql(/* GraphQL */ `
  query GetCountries {
    getCountries
  }
`);

export const getIntergiroSCA = gql(/* GraphQL */ `
  query GetIntergiroSCA($input: IntergiroSCATypeInput!) {
    getIntergiroSCA(input: $input) {
      expiresAt
      redirectUrl
      result
    }
  }
`);

export const getAuthUser = gql(/* GraphQL */ `
  query GetAuthUser {
    getAuthUser {
      id
      email
      pin
      firstName
      lastName
      kastaName
      dateOfBirth
      address {
        country
        countryCode
      }
      kyc {
        status
        level
        rejectReason
      }
      referredBy
      userStatus
    }
  }
`);

export const getUserComplianceSettings = gql(/* GraphQL */ `
  query getUserComplianceSettings {
    getUserComplianceSettings
  }
`);

export const getCashBankDetails = gql(/* GraphQL */ `
  query GetCashBankDetails {
    getCashBankDetails {
      id
      name
      currency
      balance
      beneficiary
      beneficiaryAddress {
        city
        countryCode
        postCode
        street1
      }
      paymentType
      details {
        bic
        iban
        accountNo
        sortCode
      }
      deliveredCardsCount
      cards {
        id
        displayName
        brand
        type
        status
        expMonth
        expYear
        last4
        feesTransactionIds
        createdAt
      }
      createdAt
    }
  }
`);

export const getCardCredentials = gql(/* GraphQL */ `
  query GetCardCredentials($input: GetCardCredentialsInput) {
    getCardCredentials(input: $input) {
      expiresAt
      redirectUrl
    }
  }
`);

export const getUserStatus = gql(/* GraphQL */ `
  query GetUserStatus($email: String!) {
    getUserStatus(email: $email)
  }
`);

export const getPaymentMethods = gql(/* GraphQL */ `
  query GetPaymentMethods {
    getPaymentMethods {
      id
      type
      details {
        brand
        expMonth
        expYear
        last4
      }
      createdAt
    }
  }
`);

export const getCardOutOfStock = gql(/* GraphQL */ `
  query GetCardOutOfStock {
    getCardOutOfStock
  }
`);

export const getZendeskToken = gql(/* GraphQL */ `
  query CreateZendeskToken {
    createZendeskToken
  }
`);

export const getBanners = gql(/* GraphQL */ `
  query GetBanners {
    getBanners {
      canSkip
      destinationParameters
      destinationScreen
      feature
      image
      badge
      showTo
      title
    }
  }
`);
