import {useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import {CSSTransition} from 'react-transition-group';

import {Button} from 'src/atoms/Button/Button';
import {Icon} from 'src/atoms/Icon/Icon';
import {Typography} from 'src/atoms/Typography/Typography';
import {POPUP_ANIMATION_TIMEOUT, POPUP_PORTAL_NODE} from 'src/lib/constants';
import {ActionList} from 'src/molecules/ActionList/ActionList';

import type {HTMLAttributes, MouseEvent as ReactMouseEvent, ReactNode} from 'react';
import type {ButtonProps} from 'src/atoms/Button/Button';
import type {IconType, ViewportBreakpoints} from 'src/lib/types';
import type {ActionListItemProps} from 'src/molecules/ActionList/ActionList';
import type {CSSProperties} from 'styled-components';

import {
  StyledBackdrop,
  StyledContent,
  StyledFooter,
  StyledHeader,
  StyledHeaderImageContainer,
  StyledPopUp,
  StyledTitleContainer,
} from './styled';

export interface PopUpProps {
  open?: boolean;
  title?: string | ReactNode;
  icon?: IconType;
  headerImage?: ReactNode;
  closeOnBackdropClick?: boolean;
  hasCloseButton?: boolean;
  hasBackButton?: boolean;
  onClose?: (e?: ReactMouseEvent) => void;
  onBack?: (e?: ReactMouseEvent) => void;
  closeButtonType?: ButtonProps['type'];
  backButtonType?: ButtonProps['type'];
  children?: ReactNode;
  footer?: ReactNode;
  actions?: ActionListItemProps[];
  popOver?: boolean;
  selector?: string;
  fixed?: boolean;
  fullScreenBreakpoint?: ViewportBreakpoints | '';
  overflow?: CSSProperties['overflow'];
  contentAlignment?: 'left' | 'center' | 'right';
  pageCentered?: boolean;
  onMount?: () => void;
  style?: HTMLAttributes<HTMLElement>['style'];
  $blockScroll?: boolean;
}

const Component = ({
  open = false,
  title = '',
  icon = {
    type: 'kastaicon',
    name: '',
    color: 'var(--colors-main-primary)',
  },
  headerImage = null,
  closeOnBackdropClick = false,
  hasCloseButton = false,
  hasBackButton = false,
  onClose = () => null,
  onBack = () => null,
  closeButtonType = 'primary-icon',
  backButtonType = 'primary-icon',
  children = null,
  footer = null,
  actions = [],
  popOver = false,
  fixed = false,
  fullScreenBreakpoint = '',
  contentAlignment = 'center',
  pageCentered = false,
  onMount = () => null,
  overflow = 'auto',
  ...props
}: PopUpProps) => {
  const hasActions = actions.length > 0;
  const hasIcon = Boolean(icon?.name);
  const hasButtons = !!hasBackButton || !!hasCloseButton;
  const popUpRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef(null);

  const onBackdropClickHandler = (event: ReactMouseEvent<Element, MouseEvent>): void => {
    if (closeOnBackdropClick) {
      if (!popUpRef?.current?.contains(event.target as Node)) {
        return onClose(event);
      }
    }
  };

  useEffect(() => {
    onMount && onMount();
  }, [onMount]);

  return (
    <CSSTransition
      in={open}
      timeout={POPUP_ANIMATION_TIMEOUT}
      classNames="popup"
      unmountOnExit
      appear
      enter
      ref={nodeRef}>
      <StyledBackdrop
        role="presentation"
        $fixed={!!fixed}
        $pageCentered={!!pageCentered}
        onClick={event => onBackdropClickHandler(event)}>
        <StyledPopUp
          ref={popUpRef}
          $popOver={!!popOver}
          $pageCentered={!!pageCentered}
          $overflow={overflow}
          $fullScreen={fullScreenBreakpoint}
          {...props}>
          {hasButtons || hasIcon || headerImage ? (
            <>
              {headerImage !== null && !hasIcon && (
                <StyledHeaderImageContainer>{headerImage}</StyledHeaderImageContainer>
              )}

              {hasButtons && (
                <StyledHeader $hasImage={!!headerImage}>
                  {hasBackButton && (
                    <Button size="xs" onClick={onBack} aria-label="Back" type={backButtonType}>
                      <Icon name="chevron-left" />
                    </Button>
                  )}

                  {hasCloseButton && (
                    <Button size="xs" onClick={onClose} aria-label="Close popup" type={closeButtonType}>
                      <Icon name="close-big" />
                    </Button>
                  )}
                </StyledHeader>
              )}
            </>
          ) : null}

          <StyledContent
            $alignment={contentAlignment}
            $hasImage={!!headerImage && !hasIcon}
            $hasIcon={hasIcon}
            $hasButtons={hasButtons}
            $hasBackButton={!!hasBackButton}
            $hasCloseButton={!!hasCloseButton}
            $hasActions={hasActions}>
            {hasIcon && <Icon {...icon} size={72} />}

            <StyledTitleContainer
              $alignment={contentAlignment}
              $hasImage={!!headerImage && !hasIcon}
              $hasIcon={hasIcon}
              $hasButtons={hasButtons}
              $hasBackButton={!!hasBackButton}
              $hasCloseButton={!!hasCloseButton}>
              {title !== '' && (
                <>
                  {typeof title === 'string' ? (
                    <Typography
                      as="h6"
                      variant="h7"
                      color="var(--colors-text-onBackgroundMedium)"
                      dangerouslySetInnerHTML={{__html: title}}
                    />
                  ) : (
                    title
                  )}
                </>
              )}
            </StyledTitleContainer>

            {children}

            {hasActions && <ActionList actions={actions} />}

            {footer && <StyledFooter>{footer}</StyledFooter>}
          </StyledContent>
        </StyledPopUp>
      </StyledBackdrop>
    </CSSTransition>
  );
};

const PopUp = ({
  open = false,
  onClose = () => null,
  onBack = () => null,
  children,
  selector = `#${POPUP_PORTAL_NODE}`,
  fixed = false,
  ...props
}: PopUpProps) => {
  const ref = useRef<HTMLElement | DocumentFragment>(document.querySelector(selector) as HTMLElement);

  if (fixed) {
    return (
      <Component open={true} fixed={fixed} {...props}>
        {children}
      </Component>
    );
  }

  return (
    <>
      {open
        ? createPortal(
            <Component onClose={onClose} onBack={onBack} open={open} {...props}>
              {children}
            </Component>,
            ref.current,
          )
        : null}
    </>
  );
};

export {PopUp};
