import {HTMLAttributes, ReactNode} from 'react';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';
import type {TypographyVariants, ViewportBreakpoints} from 'src/lib/types';

import {StyledTypography} from './styled';

export interface MobileTypographyProps {
  main: TypographyVariants;
  alternative: TypographyVariants;
  breakpoint: ViewportBreakpoints;
}

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  variant: TypographyVariants | MobileTypographyProps;
  weight?: '400' | 'normal' | 'bold' | undefined;
  color?: ColorType;
  transform?: 'none' | 'lowercase' | 'uppercase' | 'capitalize';
  children?: ReactNode;
  as?: keyof JSX.IntrinsicElements;
  htmlFor?: string;
}

const Typography = ({
  variant = 'body1',
  weight = undefined,
  color = undefined,
  transform = 'none',
  children = null,
  ...props
}: TypographyProps) => (
  <StyledTypography
    $variant={typeof variant === 'string' ? variant : variant['main']}
    $alternativeVariant={typeof variant === 'string' ? '' : variant['alternative']}
    $breakpoint={typeof variant === 'string' ? '0px' : variant['breakpoint']}
    $weight={weight}
    $color={color}
    $transform={transform}
    {...props}>
    {children}
  </StyledTypography>
);

export {Typography};
