import {ReactNode} from 'react';

import type {Color as ColorType} from '@kasta-io/tokens/src/types';
import type {IntRange} from 'type-fest';

import {LoadingSVG} from './LoadingSVG';
import {StyledLoading} from './styled';

export interface LoadingProps {
  changeColors?: boolean;
  size?: IntRange<0, 100>;
  type?: 'simple' | 'double';
  children?: ReactNode;
  color?: ColorType;
  label?: string;
}

const Loading = ({
  changeColors = false,
  size = 16,
  type = 'simple',
  children = null,
  label = 'Loading. Please, wait a moment.',
  color = 'var(--colors-main-primary)',
  ...props
}: LoadingProps) => (
  <StyledLoading
    aria-label={label}
    $changeColors={changeColors}
    $type={type}
    $size={size}
    $color={color}
    {...props}>
    <LoadingSVG />
    {children}
  </StyledLoading>
);

export {Loading};
