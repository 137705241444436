import {useCallback, useEffect, useMemo, useState} from 'react';

import {ScaScope as ScaScopeType} from '@/types/scaScope';
import {Button, Pill, Typography, usePopUpContext} from '@kasta-io/components';

import {SCA_BALANCE_DESCRIPTION, SCA_HISTORY_DESCRIPTION, ScaScope} from '@/constants';
import {useAppConfig} from '@/contexts/AppConfigContext';
import {useComplianceContext} from '@/contexts/ComplianceContext';
import {useUser} from '@/contexts/UserContext';
import {useSCAQuery} from '@/hooks/useSCAQuery';
import {isCashAccountActivated as isAccountActivated} from '@/utils/cash';

import type {CashAuthContext} from '@/types/cashAuthContext';

export interface Return extends CashAuthContext {
  onConfirm: () => void;
  closeSCAModal: () => void;
  onCloseModal: () => void;
  customDescription?: string;
}

interface Props {
  scope: ScaScopeType;
}

export const useCashAuthProvider = ({scope}: Props) => {
  const [customDescription, setCustomDescription] = useState<string>(
    scope === ScaScope.Balance ? SCA_BALANCE_DESCRIPTION : SCA_HISTORY_DESCRIPTION,
  );
  const [isSCARequired, setIsSCARequired] = useState(true);
  const [isSCADelayed, setIsSCADelayed] = useState(false);

  const {appConfig} = useAppConfig();

  const {cashEnabledPwa} = appConfig;

  const {user} = useUser();
  const {canSkipKyc} = useComplianceContext();
  const isCashAccountActivated = cashEnabledPwa && canSkipKyc && isAccountActivated(user?.cashAccountStatus);

  const {setPopUp, hidePopUp} = usePopUpContext();

  const {
    getIntergiroSCA,
    refetchSCAIfNeeded,
    refetch: refetchSCA,
    data,
    loading: isSCALoading,
  } = useSCAQuery(scope, !isCashAccountActivated);

  useEffect(() => {
    if (!isCashAccountActivated) {
      setIsSCARequired(false);
      return;
    }

    const isSCARequiredNow = data ? isCashAccountActivated && !!data.redirectUrl : isCashAccountActivated;
    if (isSCARequired !== isSCARequiredNow) {
      setIsSCARequired(isSCARequiredNow);
    }
  }, [isCashAccountActivated, data, isSCARequired, scope]);

  const openSCALink = useCallback(async () => {
    if (!isCashAccountActivated) {
      return;
    }

    if (isSCALoading) {
      return setIsSCADelayed(true);
    }

    const _data = await refetchSCAIfNeeded();
    if (_data?.redirectUrl) {
      window.location.href = _data?.redirectUrl;
    }
  }, [isCashAccountActivated, isSCALoading, refetchSCAIfNeeded]);

  const openSCAModal = useCallback(
    ({
      description,
      onCTAClick,
      onCancel,
    }: {
      description?: string;
      onCTAClick?: () => void;
      onCancel?: () => void;
    }) => {
      if (!isCashAccountActivated) {
        return;
      }

      if (description && description !== customDescription) {
        setCustomDescription(description);
      }

      setPopUp({
        title: 'Strong Customer Authentication',
        icon: {
          type: 'kastaicon',
          name: 'security',
        },
        children: (
          <Pill>
            <Typography variant="caption1" weight="400">
              {description || customDescription}
            </Typography>
          </Pill>
        ),
        footer: (
          <>
            <Button
              type="secondary"
              block
              onClick={() => {
                onCancel?.();
                hidePopUp();
              }}>
              Close
            </Button>
            <Button
              block
              onClick={() => {
                onCTAClick?.();
                openSCALink();
              }}>
              Complete
            </Button>
          </>
        ),
      });
    },
    [customDescription, hidePopUp, isCashAccountActivated, openSCALink, setPopUp],
  );

  const closeSCAModal = useCallback(() => {
    hidePopUp();
  }, [hidePopUp]);

  const onConfirm = useCallback(() => {
    if (isSCALoading) {
      return setIsSCADelayed(true);
    }

    return openSCALink();
  }, [isSCALoading, openSCALink]);

  useEffect(() => {
    if (isSCADelayed && !isSCALoading) {
      setIsSCADelayed(false);
      openSCALink();
    }
  }, [isSCADelayed, isSCALoading, openSCALink]);

  const value = useMemo(
    () => ({
      customDescription,
      onConfirm,
      closeSCAModal,
      isCashAccountActivated,
      isSCARequired,
      openSCAModal,
      openSCALink,
      isSCADelayed,
      isSCALoading,
      getIntergiroSCA,
      refetchSCAIfNeeded,
      refetchSCA,
      data,
    }),
    [
      customDescription,
      onConfirm,
      closeSCAModal,
      isCashAccountActivated,
      isSCARequired,
      isSCALoading,
      openSCAModal,
      openSCALink,
      isSCADelayed,
      getIntergiroSCA,
      refetchSCAIfNeeded,
      refetchSCA,
      data,
    ],
  );

  return value;
};
