import viewports from '@kasta-io/tokens/primitives/viewport.json';
import {DefaultTransition} from 'src/styled/transitions';
import {ptToPx} from 'src/styled/utils';
import styled, {css} from 'styled-components';

interface ContainerProps {
  $spacing: number;
  $error: string | undefined;
}

interface CharProps {
  $type: 'rounded' | 'square';
  $hidden: boolean;
}

const StyledChar = styled.input<CharProps>`
  position: relative;
  display: inline-flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  width: ${({$type}: CharProps) => ($type === 'rounded' ? '48px' : '52px')};
  height: ${({$type}: CharProps) => ($type === 'rounded' ? '48px' : '60px')};
  font-family: var(--font-styles-h6-family);
  font-size: var(--font-styles-h6-size);
  font-style: normal;
  font-weight: var(--font-styles-h6-weight);
  line-height: var(--font-styles-h6-lineHeight);
  color: ${({$hidden}: CharProps) => ($hidden ? 'transparent' : 'var(--colors-text-onBackgroundMedium)')};
  text-align: center;
  appearance: none;
  caret-color: ${({$hidden}: CharProps) =>
    $hidden ? 'var(--colors-primary-500)' : 'var(--colors-text-onBackgroundMedium)'};
  border: 1px solid;
  border-color: var(--colors-primary-500);
  ${DefaultTransition('background-color border-color caret-color')};
  -webkit-text-security: ${({$hidden}: CharProps) => ($hidden ? 'disc' : 'none')};

  &,
  &:focus,
  &:focus-visible {
    outline: none;
  }

  &::selection {
    background-color: var(--colors-primary-50);
  }

  &:hover {
    border-color: var(--colors-primary-500);
  }

  &:focus {
    border-color: var(--colors-primary-300);
  }

  &.filled {
    outline-style: ${({$hidden}: CharProps) => ($hidden ? 'solid' : 'none')};
  }

  ${({$type, $hidden}: CharProps) =>
    $type === 'rounded'
      ? css`
          background-color: var(--colors-primary-50);
          border-color: ${$hidden ? 'transparent' : 'var(--colors-primary-500)'};
          border-radius: 50%;

          &.filled {
            outline-width: 12px;
            outline-color: var(--colors-primary-500);
            outline-offset: -24px;
            background-color: ${$hidden ? 'transparent' : 'var(--colors-background-paper)'};
          }
        `
      : css`
          background-color: var(--colors-background-paper);
          border-color: var(--colors-text-onBackgroundUltraLight);
          border-radius: 8px;

          &.filled {
            outline-width: 8px;
            outline-color: var(--colors-primary-500);
            outline-offset: -30px;
            border-color: var(--colors-primary-500);

            @media screen and (max-width: ${viewports['tablet']}) {
              outline-width: 6px;
              outline-offset: -24px;
            }
          }
        `}

  @media screen and (max-width: ${viewports['tablet']}) {
    width: ${({$type}: CharProps) => ($type === 'rounded' ? '40px' : '40px')};
    height: ${({$type}: CharProps) => ($type === 'rounded' ? '40px' : '48px')};
    font-size: var(--font-styles-h7-size);
    line-height: var(--font-styles-h7-lineHeight);
  }
`;

const StyledContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px 0`};

  [role='icon'] {
    font-size: 32px;
  }

  ${({$error}: ContainerProps) =>
    $error &&
    css`
      ${StyledChar} {
        outline-color: var(--colors-danger-500);
        border-color: var(--colors-danger-500);
      }
    `}

  @media screen and (max-width: ${viewports['tablet']}) {
    gap: 8px;
  }
`;

const StyledAssistiveText = styled.small`
  flex: 1 0 100%;
  font-size: var(--font-styles-caption1-size);
  line-height: var(--font-styles-caption1-lineHeight);
  color: var(--colors-danger-500);
  text-align: center;
  letter-spacing: var(--font-styles-caption1-spacing);
`;

export {StyledContainer, StyledChar, StyledAssistiveText};
