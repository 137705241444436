// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"ad8f30593453c569d2ea1e8c91e45575efd715f2"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import packageJson from '@/package.json';
import * as Sentry from '@sentry/nextjs';

console.log('[Sentry] Client config read?', process.env.NEXT_PUBLIC_VERCEL_ENV);

const networkErrorMessages = [
  'Failed to fetch', // Fetch API errors
  'Network request failed', // Generic network errors
  'Load failed', // Resource loading errors
  'Failed to load resource', // Resource loading errors (Chrome)
  'Unable to fetch', // Additional fetch errors
];

Sentry.init({
  dsn: `${process.env.NEXT_PUBLIC_SENTRY_DSN}`,
  environment: `${process.env.NEXT_PUBLIC_VERCEL_ENV}`,
  release: `pwa@${packageJson.version}`,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: ["Cannot read properties of undefined (reading 'subscriptionFailedCallback')"],

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
      stickySession: true,
    }),
  ],
  beforeSend(event: Sentry.ErrorEvent) {
    // Check if the event contains exceptions
    if (event.exception && event.exception.values) {
      for (const exception of event.exception.values) {
        // Ignore network-related errors based on type or message
        if (
          exception.type === 'TypeError' || // Common fetch errors
          exception.type === 'NetworkError' || // Generic network errors
          (exception.value && networkErrorMessages.some(msg => exception.value?.includes(msg))) // Check if the error message matches any in the list
        ) {
          event.level = "warning";
        }
      }
    }

    return event; // Send other errors to Sentry
  },
});
