import {DefaultInput} from 'src/styled/mixins';
import {DefaultTransition} from 'src/styled/transitions';
import {defaultTypographyStyles, ptToPx} from 'src/styled/utils';
import styled, {css} from 'styled-components';

interface ContainerProps {
  $spacing: number;
  $disabled?: boolean;
}

interface OptionProps {
  $selected?: boolean;
  $disabled?: boolean;
}

const StyledContainer = styled.div<ContainerProps>`
  position: relative;
  margin: ${({$spacing}: ContainerProps) => `${ptToPx($spacing)}px`} 0;

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    ${DefaultInput};

    [role='icon'] {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }
`;

const StyledOptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: var(--model-zindex-base);
  width: 100%;
  max-height: 168px;
  overflow: auto;
  background-color: var(--colors-background-paper);
  border-radius: 12px;
  box-shadow: var(--shadow--04dp);
`;

const StyledOption = styled.li<OptionProps>`
  padding: 12px;
  text-align: left;
  cursor: pointer;
  ${defaultTypographyStyles('caption1')};
  background-color: ${({$selected}: OptionProps) =>
    $selected ? 'var(--colors-primary-100)' : 'var(--colors-background-paper)'};
  ${DefaultTransition()};

  &:hover {
    background-color: var(--colors-primary-25);
  }

  ${({$disabled}: OptionProps) =>
    $disabled &&
    css`
      pointer-events: none;
      background-color: var(--colors-background-default);
      opacity: 0.8;
    `}
`;

export {StyledContainer, StyledOptionsList, StyledOption};
