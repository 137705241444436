import {useCallback, useEffect, useState} from 'react';

interface usePopUpProps {
  blockScroll?: boolean;
  closeOnEsc?: boolean;
}

const usePopUp = ({blockScroll = true, closeOnEsc = false}: usePopUpProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleBodyScroll = (block: boolean) => {
    if (block) {
      document.body.classList.add('no-scroll');
      document.querySelector('html')?.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      document.querySelector('html')?.classList.remove('no-scroll');
    }
  };

  const escHandler = useCallback((event: KeyboardEvent) => {
    if (event.key.toLowerCase() === 'escape') {
      closePopUp();
    }
  }, []);

  const openPopUp = () => {
    toggleBodyScroll(blockScroll);
    setIsVisible(true);
  };

  const closePopUp = () => {
    toggleBodyScroll(false);
    setIsVisible(false);
  };

  const togglePopUp = () => {
    if (isVisible) {
      closePopUp();
    } else {
      openPopUp();
    }
  };

  useEffect(() => {
    if (closeOnEsc) {
      document.addEventListener('keydown', escHandler, false);
    }

    return () => {
      if (closeOnEsc) {
        document.removeEventListener('keydown', escHandler, false);
      }
    };
  }, [escHandler, closeOnEsc]);

  return {
    isVisible,
    openPopUp,
    closePopUp,
    togglePopUp,
    toggleBodyScroll,
  };
};

export {usePopUp};
