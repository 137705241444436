import {createAuthLink} from 'aws-appsync-auth-link';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';

import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject} from '@apollo/client';
import {loadDevMessages, loadErrorMessages} from '@apollo/client/dev';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {mergeDeep} from '@apollo/client/utilities';

import {ERROR_LEVEL, graphQLErrorTypeToMessages, ONE_SECOND} from '@/constants';
import {getClientUserToken} from '@/lib/firebase';
import {isBrowser, isDev} from '@/utils/device';

import type {ToastNotificationContextType} from '@/contexts/ToastNotificationContext';
import type {AuthOptions} from 'aws-appsync-auth-link';
import { tracking } from '@/services/tracking/TrackingService';

const url = process.env.NEXT_PUBLIC_GRAPHQL_API_URL as string;
const region = process.env.NEXT_PUBLIC_GRAPHQL_REGION as string;
const httpLink = new HttpLink({uri: url});
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: error => Boolean(error),
  },
});

if (isDev) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const createErrorLink = (setToastNotification?: ToastNotificationContextType['setToastNotification']) =>
  onError(({graphQLErrors, networkError, response}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({message, locations, path}) => {
        const messageToLog = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;

        if (!isDev) {
          tracking.logError({
            error_level: ERROR_LEVEL.CRITICAL,
            error_message: messageToLog,
            error_message_id: 'error_to_fetch',
          });
        } else {
          console.log(messageToLog);
        }
      });

      // Errors in graphQLErrorTypeToMessage constant are expected errors except for default unexpected error
      const graphQLErrorTypeToMessageObjectValues = Object.values(graphQLErrorTypeToMessages);
      let errorMessageInToast = graphQLErrorTypeToMessages.UNEXPECTED_ERROR.displayMessage;
      let showNotification = true;

      const isExpextedError = graphQLErrors.some(({message}) =>
        // We use this pattern to check if error message contains any of the expected errors
        // We want to check if the error.apiErrorMessage is a part of the error message coming from the request, not the opposite one
        graphQLErrorTypeToMessageObjectValues.some(error => message.includes(error.apiErrorMessage)),
      );

      if (isExpextedError) {
        // We find and set the first error.displayMessage available for an graphQLErrorTypeToMessageObjectValues
        graphQLErrors.forEach(({message}) => {
          if (errorMessageInToast && errorMessageInToast.summary !== 'Unexpected Error') {
            return;
          }
          graphQLErrorTypeToMessageObjectValues.forEach(error => {
            if (message.includes(error.apiErrorMessage)) {
              errorMessageInToast = error.displayMessage;
              showNotification = Boolean(error.notification);
            }
          });
        });
      }

      // Only show toast notification when it's defined on constants.ts
      if (!showNotification) {
        return;
      }

      setToastNotification?.({
        messageConfig: {
          // It will be 'Unexpected Error' if there is no error.displayMessage for an error.apiErrorMessage
          // Or if it is not an expected error
          ...errorMessageInToast,
          life: ONE_SECOND * 5,
          severity: 'error',
          closable: true,
        },
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${JSON.stringify(networkError)}`);
      if (isDev) {
        setToastNotification?.({
          config: {position: 'top-right'},
          messageConfig: {
            summary: 'Network Error',
            severity: 'error',
          },
        });
      }
    }
  });

function createIsomorphLink({
  serverUserToken,
  setToastNotification,
}: {
  serverUserToken?: string;
  setToastNotification?: ToastNotificationContextType['setToastNotification'];
}) {
  const auth = {
    type: 'OPENID_CONNECT',
    jwtToken: () => serverUserToken || getClientUserToken(),
  } as AuthOptions;

  const linksList = [
    retryLink,
    createErrorLink(setToastNotification),
    createAuthLink({url, region, auth}),
    httpLink,
  ];
  // Sometimes the creation of the subscriptionHandshakeLink fails when reloading the page
  // In case it does not fail, we remove the httpLink from the list of links and add the subscriptionHandshakeLink
  try {
    const subscriptionHandshakeLink = createSubscriptionHandshakeLink({url, region, auth}, httpLink);
    linksList.pop();
    linksList.push(subscriptionHandshakeLink);
  } catch (error: any) {
    console.error('Error creating subscriptionHandshakeLink', error.message);
  }

  return ApolloLink.from(linksList);
}

function createApolloClient({
  serverUserToken,
  setToastNotification,
}: {
  serverUserToken?: string;
  setToastNotification?: ToastNotificationContextType['setToastNotification'];
}) {
  return new ApolloClient({
    ssrMode: !isBrowser(),
    // https://www.apollographql.com/docs/react/performance/server-side-rendering/#overriding-fetch-policies-during-initialization
    ssrForceFetchDelay: 100,
    link: createIsomorphLink({serverUserToken, setToastNotification}),
    connectToDevTools: isDev,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getTransactions: {
              keyArgs: false,
              merge(existing, incoming) {
                if (!existing?.items) {
                  return incoming;
                }
                const items = [...existing.items, ...incoming.items];
                const key = '__ref';
                const arrayUniqueByKey = [...new Map(items.map(item => [item[key], item])).values()];
                return {
                  items: arrayUniqueByKey,
                  lastKey: incoming.lastKey,
                };
              },
            },
          },
        },
        UserKyc: {
          merge: true,
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });
}

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
// Inspired by this: https://medium.com/@ahsan-ali-mansoor/apollo-client-cache-rehydration-in-next-js-d1d7c693699e
export const initializeClientApollo = (
  initialState: NormalizedCacheObject | null = null,
  setToastNotification: ToastNotificationContextType['setToastNotification'],
) => {
  const _apolloClient = apolloClient ?? createApolloClient({setToastNotification});

  // The initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = mergeDeep(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const getApolloServer = async (serverUserToken: string) => {
  return createApolloClient({serverUserToken});
};
