import {IntergiroError, IntergiroErrorMessage} from '@/types/serviceErrors';
import {ERROR_MESSAGE_ID} from '@/types/tracking';

import {ApolloError} from '@apollo/client';

import {ERROR_LEVEL} from '@/constants';
import {tracking} from '@/services/tracking/TrackingService';

export const getErrorMessage = ({error}: {error: any}): string => {
  let errorMessage = '';

  if (error instanceof ApolloError) {
    // 1st check if the outer 'message' field is enough,
    // in cause it's not then the 1st graphQLErrors[0] should deal with it
    errorMessage = error?.message || error.graphQLErrors[0].message;
    if (errorMessage) {
      // 2nd check if he 'errorMessage' is a JSON
      if (errorMessage && isJsonString(errorMessage)) {
        const parsedError = JSON.parse(errorMessage) as IntergiroErrorMessage;
        // 3rd check if an object with one error or an array with multiple errors
        const isArray = Array.isArray(parsedError);
        if (isArray) {
          errorMessage = parsedError.map((item: IntergiroError) => item.message).join('. ');
        } else {
          errorMessage = parsedError?.message;
        }
      }
      return errorMessage;
    }
  }

  // 4th in case message is available but is not ApolloError
  if (Object.hasOwn(error, 'message')) {
    errorMessage = error.message;
    if (errorMessage) {
      // 5th check if 'errorMessage' is a JSON
      if (errorMessage && isJsonString(errorMessage)) {
        const parsedError = JSON.parse(errorMessage) as IntergiroErrorMessage;
        const isArray = Array.isArray(parsedError);
        // 6th check if an object with one error or an array with multiple errors
        if (isArray) {
          errorMessage = parsedError.map((item: IntergiroError) => item.message).join('. ');
        } else {
          errorMessage = parsedError?.message;
        }
      }
      return errorMessage;
    }
  }

  // 7th check if the error has array of 'errors'
  if (Object.hasOwn(error, 'errors') && Array.isArray(error.errors)) {
    errorMessage = error.errors[0]?.message;

    if (errorMessage) {
      // 8th check if 'errorMessage' is a JSON
      if (errorMessage && isJsonString(errorMessage)) {
        const parsedError = JSON.parse(errorMessage) as IntergiroErrorMessage;
        // 9th check if an object with one error or an array with multiple errors
        errorMessage = Array.isArray(parsedError) ? parsedError[0].message : parsedError?.message;
      }
      return errorMessage;
    }
  }

  return errorMessage;
};

export const handleErrorMessage = ({
  error,
  trackingId,
}: {
  error: any;
  trackingId?: ERROR_MESSAGE_ID;
}): string => {
  let knownErrorMessage = getErrorMessage({error});
  /**
   * In case this error happens we improve the error message shown to the user, code snippet for it:
   * if (knownErrorMessage === graphQLErrorTypeToMessages.USER_ALREADY_HAS_CARD.apiErrorMessage) {
   * knownErrorMessage = "It seems you already have a debit card that's active or pending activation.";
   * }
   */

  trackingId &&
    tracking.logError({
      error_message: knownErrorMessage,
      error_level: ERROR_LEVEL.CRITICAL,
      error_message_id: trackingId,
    });
  return knownErrorMessage;
};

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const NOT_ERRORS: ERROR_MESSAGE_ID[] = [
  'auth/kasta/user-already-exists-in-db', 
  'auth/kasta/resume-sign-up',
  'auth/kasta/is-new-user',
  'auth/kasta/user-not-found-in-db',
];

/**
 * Checks if the provided code should not be an error but an information.
 *
 * @param {ERROR_MESSAGE_ID} code - The error message code to be checked.
 * @returns {boolean} - Returns `true` if the code is not an error, otherwise `false`.
 */
export const isNotError = (code: ERROR_MESSAGE_ID): boolean => {
  return NOT_ERRORS.includes(code);
};